import React, { Component, Fragment } from 'react';
import './index.css';
import { auth } from 'datastore/firebase';
import { Redirect } from 'react-router-dom'
import AuthUserContext from 'components/auth/AuthUserContext';
import {
  withRouter
} from 'react-router-dom'
import AppNavBar from 'components/AppNavBar';

class LoginScreen extends Component {
	constructor (props) {
		super(props)
		this.state = {
      login : {
        email: '',
        password: '',
      },
      error: {
        code: null,
        message: null
      },
      showPass: false,
			prevPath: '/home'
    }

    this.updateLoginState = this.updateLoginState.bind(this);
  }

	componentDidMount() {
		console.log("[Component][Login]", this.props.location.state.prevPath)
		if(this.props.location.state) {
			if(this.props.location.state.prevPath) {
				this.setState({ prevPath: this.props.location.state.prevPath })
			}
		}
	}

  signIn = () => {
		return auth.signInWithEmailAndPassword(this.state.login.email, this.state.login.password)
			.catch(error => {
		    var errorCode = error.code;
		    var errorMessage = error.message;
		    this.setState({
		      'error': {
		        code: errorCode,
		        message: errorMessage
		      }
		    })
			})
  }

  friendlyError(code) {
    if(code === null)
      return;

    switch(code) {
      case 'auth/user-not-found':
        return 'Account not found, register with a developer!';
      case 'auth/wrong-password':
        return 'Invalid password!';
      case 'auth/internal-error':
        return 'Google is having issues right now, refresh or redo your last action :('
      default:
        return code;
    }
  }

  updateLoginState(event) {
    var name = event.target.name;
    var val = event.target.value;
    var curState = this.state.login;
    switch(name) {
      case 'email':
        curState.email = val;
        this.setState({ login : curState });
        break;
      case 'password':
        curState.password = val;
        this.setState({ login : curState });
        break;
      default:
        break
    }
  }

	navigateToHome = () => {
		return this.props.history.push( '/home' )
	}

  render() {
    var friendlyErrorMessage = this.friendlyError(this.state.error.code);
    var errorMessage = {
      'email': this.state.error.code !== 'auth/wrong-password' ? friendlyErrorMessage : null,
      'password': this.state.error.code === 'auth/wrong-password' ? friendlyErrorMessage : null
    };

    var inputType = {
      'password': this.state.showPass === true ? 'text': 'password'
    }

    return (
			<AuthUserContext.Consumer>
				{authUser => authUser
					? (
						<Redirect to={this.state.prevPath} />
					) : (
						<Fragment>
							<AppNavBar showBackBtn={true} onBackBtnClickAction={() => this.navigateToHome()} />
							<div className="login-container">
				        <div className="login-form">
				          <p className="login-title">{this.props.app_name} Login</p>
				          <div className="input-field">
				            <input className="email-field" placeholder={'Email'} name={'email'} value={this.state.login.email} onChange={(event) => {this.updateLoginState(event)}} />
				            <div className="error-message">
				              <p>{errorMessage.email}</p>
				            </div>
				          </div>
				          <div className="input-field">
				            <input className="password-field" type={inputType.password} placeholder={'Password'} name={'password'} value={this.state.login.password} onChange={(event) => {this.updateLoginState(event)}} />
				            <div className="error-message">
				              <p>{errorMessage.password}</p>
				            </div>
				          </div>
				          <div className="show-password-field">
				            <input type={'checkbox'} onChange={(event) => {this.setState({ showPass : !this.state.showPass})}}/>
				            <p>Show password</p>
				          </div>
				          <button className="submit-button" onClick={() => {return this.signIn()}}>login</button>
				        </div>
				      </div>
						</Fragment>
					)
				}
			</AuthUserContext.Consumer>
    );
  }
}

export default withRouter(LoginScreen)
