import {
  FETCH_TEST_DATA,
  FETCH_TEST_DATA_SUCCESS,
  FETCH_TEST_DATA_FAILURE
} from './BRH_API';

export const BRH_API = {
  'FETCH_TEST_DATA': FETCH_TEST_DATA,
  'FETCH_TEST_DATA_SUCCESS': FETCH_TEST_DATA_SUCCESS,
  'FETCH_TEST_DATA_FAILURE': FETCH_TEST_DATA_FAILURE
}