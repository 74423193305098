import { BRH_API } from 'actions/types';
const FETCH_TEST_DATA = BRH_API.FETCH_TEST_DATA;
const FETCH_TEST_DATA_SUCCESS = BRH_API.FETCH_TEST_DATA_SUCCESS;
//const FETCH_TEST_DATA_FAILURE = BRH_API.FETCH_TEST_DATA_FAILURE;

export function updateDates(startDate, endDate) {
  var isReady = ((startDate != null) && (endDate != null))
  return {
    type: FETCH_TEST_DATA,
    payload: {
      startDate,
      endDate,
      isReady
    }
  }
}

export function updateTotals( chartName, data ) {
  return {
    type: FETCH_TEST_DATA_SUCCESS,
    payload: {
      keyName: chartName,
      keyData: data
    }
  }
}

export function getTotal( chartName ) {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const value = rootReducer.totalsMatrix[ chartName ];
    console.log("getTotal(" + chartName + ")", value)
    return value || "N/A"
  };
}
/*
import axios from 'axios';

const apiUrl = 'https://api.github.com/users/KrunalLathiya';

export const fetchData = (data) => {
  return {
    type: FETCH_GITHUB_DATA,
    data
  }
};

export const fetchGithubData = () => {
  return (dispatch) => {
    return axios.get(apiUrl)
      .then(response => {
        dispatch(fetchData(response.data))
      })
      .catch(error => {
        throw(error);
      });
  };
};
*/
