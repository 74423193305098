import React, { Component } from 'react';
import { ResponsiveBarCanvas } from '@nivo/bar';
import Lottie from 'react-lottie';
import './index.css';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { updateTotals } from 'actions';
import { isProduction, chartClassStyles } from 'datastore/constants';

function getSum(total, num) {
  return total + num;
}

var API_DATE_FORMAT = {
  'v1': "MM-DD-YYYY",
  'v2': "YYYY-MM-DD"
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: require('datastore/chart-loader.json'),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    progressiveLoad: true,
    className: "svg-loader"
  }
}

class FancyBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoadingData: false,
      isStopped: false,
      isPaused: false
    }

    this.getData = this.getData.bind(this);
    //this.tooltipGenerator = this.tooltipGenerator.bind(this);
  }

  getData() {
    const { appendQueryParams, queryParams, apiDateVersion, chartName } = this.props;

    var startDate = moment(this.props.startDate).startOf('day').format(API_DATE_FORMAT[apiDateVersion]) || moment().startOf('week').format(API_DATE_FORMAT[apiDateVersion])
    var endDate = moment(this.props.endDate).endOf('day').format(API_DATE_FORMAT[apiDateVersion]) || moment().endOf('week').format(API_DATE_FORMAT[apiDateVersion])
    var axiosRequestObject = {
      method: 'get',
      url: this.props.API_URL,
      responseType: 'json'
    }
    var finalParams = Object.assign({}, queryParams, { startDate, endDate })

    if(appendQueryParams) {
      axiosRequestObject.url = axiosRequestObject.url + finalParams.startDate + '/' + finalParams.endDate;
    } else {
      //TODO: Make a less hacky fix
      axiosRequestObject.params = Object.assign({}, finalParams, { from: startDate, to: endDate, startDate: undefined, endDate: undefined });
    }

    return axios(axiosRequestObject)
      .then(function(response) {
        if(!isProduction) console.log("getData", response.data)
        if(response.status === 200)
          return response.data;
        else
          throw response.data.error;
      })
      .then(newData => {
        var dates = newData.dates;
        if(!dates) {
          dates = newData.data;
        }

        var totalData = Object.values(dates).reduce(getSum)
        totalData = parseFloat(parseFloat( totalData, 10 ).toFixed(2))
        this.props.updateTotals( chartName, totalData )

        return Promise.all(Object.keys(dates).map( dateObj => {
          return {
            date: dateObj,
            total: dates[dateObj]
          }
        }))
      })
      .then(unsortedData => {
        this.setState({ isLoadingData: false })
        return unsortedData.sort(function(a, b){
          var itemA = a.date;
          var itemB = b.date;
          if(itemA > itemB) {
            return -1;
          } else if ( itemA < itemB ) {
            return 1;
          } else {
            return 0;
          }
        })
      })
      .catch(function(error){
        //TODO: Send back to motherbase!
        console.error(error);
        throw error;
      })
  }

  componentDidUpdate(prevProps, prevState) {
    //Please don't spam requests again silly component kthx
    const { isReady, startDate, endDate } = this.props;
    if(!isProduction) console.log("DEMO", isReady, startDate, endDate)

    if(isReady && ( startDate != null && endDate != null ) && this.state.isLoadingData === false) {
      if( (startDate !== prevProps.startDate) || (endDate !== prevProps.endDate) ) {
        this.setState({ isLoadingData: true })
        return this.getData()
          .then(data => {
            this.setState({ data })
          })
      }
    }
  }

  componentDidMount() {
    if(!isProduction) console.log("FancyBarChart initialized.")
  }

  render() {
    const { barColor, chartName } = this.props;
    const isLoadingData = this.state.isLoadingData;
    var chartHeight = (this.state.data.length * 20);

    if(chartHeight === 0) {
      chartHeight = 500;
    }

    return !isLoadingData ? (
      <div className={'Chart'} style={{ height: chartHeight }} >
      {
        this.state.data.length === 0 ? (
          <h2 style={{textAlign: 'center', margin: 'auto', color: barColor}}>Select a date range (top left of screen)</h2>
        ) : (
            <ResponsiveBarCanvas
              data={this.state.data}
              keys={[
                  "date",
                  "total"
              ]}
              indexBy="date"
              layout="horizontal"
              margin={{
                  "top": 0,
                  "right": 90,
                  "bottom": 0,
                  "left": 90
              }}
              padding={0.3}
              colors={ barColor || 'rgb(52, 73, 94)' }
              colorBy="total"
              borderColor="inherit:darker(1.6)"
              axisBottom={{
                  "orient": "bottom",
                  "tickSize": 5,
                  "tickPadding": 5,
                  "tickRotation": 0,
                  "legend": `Total ${ chartName ? chartName : '' }`,
                  "legendPosition": "middle",
                  "legendOffset": 36
              }}
              axisLeft={{
                  "orient": "left",
                  "tickSize": 5,
                  "tickPadding": 5,
                  "tickRotation": 0,
                  "legend": "",
                  "legendPosition": "middle",
                  "legendOffset": -40
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor="inherit:darker(1.6)"
              legends={[
                  {
                      "dataFrom": "keys",
                      "anchor": "bottom-right",
                      "direction": "column",
                      "translateX": 120,
                      "itemWidth": 100,
                      "itemHeight": 20,
                      "itemsSpacing": 2,
                      "symbolSize": 20
                  }
              ]}
              theme={{
                  "tooltip": {
                      "container": {
                          "fontSize": "13px"
                      }
                  },
                  "labels": {
                      "textColor": "#555"
                  }
              }}
              //tooltip={this.tooltipGenerator}
          />
        )
      }
      </div>
    ) : (
      <div style={{ display: 'inline-flex', margin: 'auto', position: 'relative' }}>
        <div style={{ display: 'inline-flex', flexDirection: 'column', textAlign: 'center'}}>
          <Lottie
            options={ Object.assign({}, defaultOptions, { rendererSettings: { ...defaultOptions.rendererSettings, className: `svg-loader ${chartClassStyles[ chartName ]}` } }) }
            height={400}
            width={400}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused}
          />
        </div>
        <h2 style={{ position: 'absolute', right: '6rem', bottom: '1rem'}}>Downloading data...</h2>
      </div>
    )
  }
}

var mapStateToProps = (state) => {
  const { startDate, endDate, isReady, isLoadingData } = state.rootReducer;
  if(!isProduction) console.log("state", state)
  return { startDate, endDate, isReady, isLoadingData };
}

export default connect(mapStateToProps, { updateTotals })(FancyBar);



/*
  tooltipGenerator(tooltipData) {
    var data = tooltipData.data;
    var type = this.props.type;
    var typeString;

    switch(type) {
      case "jobCount":
        typeString = "# of Scheduled Jobs";
        break;
      case "jobScheduledHours":
        typeString = "# Scheduled Hours";
        break;
      default:
        typeString = "# of Scheduled Jobs";
        break;
    }

    console.log(tooltipData)
    return (
      <div className={'tooltip'}>
        <p className={'tooltip-date'}>Date: {data.date}</p>
        <p className={(tooltipData.id === "aboveHours" ? "big-text" : null)} style={{color: (tooltipData.id === "aboveHours" ? tooltipData.color : null)}}>{typeString} above: {data.aboveHours}</p>
        <p className={(tooltipData.id === "belowHours" ? "big-text" : null)} style={{color: (tooltipData.id === "belowHours" ? tooltipData.color : null)}}>{typeString} below: {data.belowHours}</p>
        <div className={'tooltip-total'}>
          <p>Total {typeString}:</p>
          <b>{data.totalJobs}</b>
        </div>
      </div>
    );
  }
*/
