import React from 'react';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
//import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BarChart from '@material-ui/icons/BarChart';
import TableChart from '@material-ui/icons/TableChart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';

const styles = {
  card: {
    margin: 'auto',
  },
  button: {
    background: 'linear-gradient(to right, #00d2ff, #3a7bd5)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(58, 123, 213, .3)',
    color: 'white',
    height: '44pt',
    width: '100%',
    padding: '0 8px',
    margin: '8px'
  },
  title: {
    fontSize: 16,
  },
  icon: {
    width: '100%',
    height: '128px',
    margin: 'auto'
  },
  pos: {
    marginBottom: 12,
  },
}

class SimpleCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  chooseIcon = ( iconType, iconStyles ) => {

    switch( iconType ) {
      case 'table':
        return (
          <TableChart className={iconStyles}/>
        );
      case 'aggregator':
        return (
          <AssignmentIcon className={iconStyles}/>
        );
      case 'dashboard':
        return (
          <DashboardIcon className={iconStyles}/>
        );
      default:
        return (
          <BarChart className={iconStyles}/>
        );
    }
  }

  createDescriptionField = ( description ) => {
    if(typeof(description) === "object") {
      return description.map( (descriptionObj, indx) => {
        return (
          <Typography key={'typography_description_line_' + indx} variant="body2">
            {descriptionObj}
          </Typography>
        )
      })
    } else {
      return (
        <Typography variant="body2">
          {description}
        </Typography>
      )
    }
  }

  render() {
    const { title, subtitle, description, classes, clickAction, iconType } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent style={{ maxHeight: classes.card.minHeight }}>
          <Typography variant="h4" component="h2">
            {title}
          </Typography>
          <br />
          { this.chooseIcon( iconType, classes.icon ) }
          <Typography className={classes.pos} color="textSecondary">
            {subtitle}
          </Typography>
          { description != null ? this.createDescriptionField( description ) : null }
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={() => clickAction()} className={classes.button}>View</Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(SimpleCard);
