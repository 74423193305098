import React from 'react';
import HomeScreen from 'components/screens/home';
import GraphOverviewScreen from 'components/screens/graphList';
import PivotTableScreen from 'components/screens/pivotTable';
import ReportGenScreen from 'components/screens/reportGen';
import DashboardGenScreen from 'components/screens/dashboardGen';
import DashboardHomeScreen from 'components/screens/dashboardHome';
import DashboardScreen from 'components/screens/dashboardScreen';
import LoginScreen from 'components/screens/login';
import LogOutScreen from 'components/screens/logout';
import { history } from 'datastore/history';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import './App.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-pivottable/pivottable.css';
import withAuthentication from 'components/auth/withAuthentication';

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/home" component={HomeScreen} />
        <Route path="/login" render={() => <LoginScreen /> } />
        <Route path="/logout" render={() => <LogOutScreen /> } />
        <Route path="/graphs" component={GraphOverviewScreen} />
        <Route path="/pivot-table" component={PivotTableScreen} />
        <Route path="/reports" component={ReportGenScreen} />
        <Route path="/dashboards" component={DashboardHomeScreen} />
        <Route path="/dashboard/view/:id" component={DashboardScreen} />
        <Route path="/dashboard/edit/:id" component={DashboardGenScreen} />
        <Redirect to="/home" />
      </Switch>
    </Router>
  );
}

/*<Route path="/login" component={LoginScreen} />*/

export default withAuthentication(App);
