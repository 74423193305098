import React, { Component, Fragment } from 'react';
import Odometer from 'react-odometerjs';
import AddBox from '@material-ui/icons/AddBox';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import 'odometer/themes/odometer-theme-default.css';
import './index.css';
import ModalHandler from './modal-handler';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import moment from 'moment';
import AppNavBar from 'components/AppNavBar';
import { database } from 'datastore/firebase';
import { dashboardDataRef, dataSources, refreshTimes, periodTimes, API_DATE_FORMAT } from 'datastore/constants';
import withAuthorization from 'components/auth/withAuthorization';

//Metric row
/*
  <div className={'metric-row ' + tileType}>
    {tileData.map((tileObj, tileIndx) => {
      return (
        <div className='metric-tile'>
          <div style={ (tileObj.type === 'graph' ? { height: '75%', width: '100%' } : null) } className={'metric-tile-value ' + (tileObj.type === 'graph' ? 'metric-graph-title' : null)}>
            {this.renderTile(tileObj.type, tileObj.value)}
          </div>
          <b className={'metric-tile-title ' + (tileObj.type === 'graph' ? 'metric-graph-title' : null)}>{tileObj.name}</b>
        </div>
      );
    })}
  </div>
*/

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

//TODO: Implement tooltips + helper tooltips (guide thing that we used on fieldtechindex)
//TODO: Implement drag-n-drop tiles
//TODO: Move/Align row controls to right, seperate from data
//TODO: Hide row if no tile data (remove if saved)
//TODO: Add data mapping to tiles (currently sets value only)
//TODO: State mgmt (if modal close, clear state?)
//TODO: Add API polling and store data in state (currenty on demand)
//TODO: 'Best Name' for tile
const SelectKeyComponent = props => {
  var { rowNum, tileNum, filterObj, filterIndx, filters, handleChangeFunction, modalKeys } = props;
  var filterArr = filters.map(y => {
    if(y.key !== filterObj.key) {
      return y.key;
    } else {
      return null;
    }
  }).filter(x => x !== null)
  //console.log("filterArr", filterArr)
  return (
    <Fragment>
      <InputLabel id={`select-filters-key-label-${filterIndx}`}>Key Filter</InputLabel>
      <Select
        labelId={`select-filters-key-label-${filterIndx}`}
        id={`select-filters-key-${filterIndx}`}
        value={filterObj ? filterObj.key : ''}
        onChange={(event) => {
          var theData = filters;
          if(theData === undefined || theData === null) {
            theData = []
          } else {
            theData[filterIndx].key = event.target.value
          }

          if(theData[filterIndx].key === 'date' && typeof(theData[filterIndx].value) === 'string') {
            theData[filterIndx].value = []
          }

          return handleChangeFunction(rowNum, tileNum, { type: 'filters', value: theData })
        }}
        >
          <MenuItem disabled value="">
            <em>Select a Filter</em>
          </MenuItem>
          {modalKeys !== undefined ?
            modalKeys.filter(x => !['value', 'count'].includes(x))
            .filter(x => !filterArr.includes(x))
            .map(sourceObj => (
            <MenuItem key={ 'filters_select_key_option_' + sourceObj} value={sourceObj}>
              {sourceObj}
            </MenuItem>
          )) : null
        }
      </Select>
    </Fragment>
  );
}

class DashboardGenScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id || null,
      name: "Dashboard Name",
      editMode: true,
      dataSource: null,
      tileData: [],
      isModalOpen: false,
      modalType: null,
      modalValue: {
        datasources: []
      },
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
      openPeriodSelect: false,
      openRefreshIntervalSelect: false,
      selectedPeriod: periodTimes[0].value,
      refreshInterval: refreshTimes[1].value,
      periodTimes: periodTimes,
      refreshTimes: refreshTimes,
      dashList: []
    }
  }
/*
  getDashboardFromList = ( dashboardId ) => {
    var dashboardItem = this.state.dashList.find( x => {
      return x.id === parseInt(dashboardId, 10)
     })

     if(dashboardItem) {
       console.log("dashboardItem", dashboardItem)
       var modalValue = this.state.modalValue;
       modalValue.datasources = dashboardItem.datasources;
       this.editPeriod( dashboardItem.period )
        .then(() => {
          return new Promise((resolve) => this.setState({ id: dashboardId, modalValue: modalValue, tileData: dashboardItem.data, name: dashboardItem.name }, () => resolve()))
       })
     } else {
       throw new Error("JigError/Invalid_Dashboard_ID")
     }
  }
*/

  getDashboardDataFromFirebase = ( dashboardId ) => {
    return database.ref(`${dashboardDataRef}/${dashboardId}`).once("value")
  }


  componentDidMount() {
    var dashboardId = this.props.match.params.id;
    console.log("[Component][DashboardGenScreen] componentDidMount()", dashboardId)
    return this.getDashboardDataFromFirebase( dashboardId )
      .then( snap => {
        var dashboardData = snap.val()
        if(!dashboardData) {
          console.log("New Dashboard Created")
        } else {
          var dashboardItem = null;
          try {
            dashboardItem = JSON.parse(dashboardData)
          } catch (e) {
            console.log("Failed to parse JSON data!")
          }

          if(dashboardItem === null) {
            throw new Error("JigError/Invalid_Dashboard_ID")
          }

          console.log("dashboardItem", dashboardItem)
          var modalValue = this.state.modalValue;
          modalValue.datasources = dashboardItem.datasources;
          return this.editPeriod( dashboardItem.period )
            .then(() => {
              return new Promise(resolve => this.setState({ id: dashboardId, modalValue: modalValue, tileData: dashboardItem.data, name: dashboardItem.name, refreshInterval: dashboardItem.refreshInterval }, () => resolve()))
            })
        }
      })
      .catch(err => {
        return this.props.history.push("/dashboards", { prevPath: "/dashboards" })
      })
  }

  componentWillUnmount() {
    console.log("[Component][DashboardGenScreen] componentWillUnmount()")
  }

  updateDates = ( startDate, endDate ) => {
    return new Promise((resolve) => this.setState({startDate: startDate, endDate: endDate}, () => resolve()) );
  }

  editRefreshInterval = ( selectedVal ) => {
    var { refreshTimes } = this.state;
    var selectedIndx = refreshTimes.map(x => x.value).indexOf(selectedVal)
    var selectedInterval = refreshTimes[selectedIndx];
    return new Promise((resolve) => this.setState({refreshInterval: selectedInterval.value}, () => resolve()))
  }

  editPeriod = ( selectedVal ) => {
    var { periodTimes } = this.state;
    var selectedIndx = periodTimes.map(x => x.value).indexOf(selectedVal)
    var selectedPeriod = periodTimes[selectedIndx];
    var startNum = selectedPeriod.startDate.number;
    var startUnit = selectedPeriod.startDate.unit;
    var endNum = selectedPeriod.endDate.number;
    var endUnit = selectedPeriod.endDate.unit;
    return new Promise((resolve) => this.setState({selectedPeriod: selectedPeriod.value}, () => resolve( this.updateDates( moment().subtract(startNum, startUnit).startOf('day'), moment().subtract(endNum, endUnit).endOf('day') ) )))
  }

  setModalType = (typeOfModal) => {
    console.log(`setModalType(${typeOfModal})`)
    if(this.state.isModalOpen === false) {
      console.log(`Setting modal type to ${typeOfModal}`)
      return new Promise((resolve) => this.setState({modalType: typeOfModal}, () => resolve()));
    } else {
      return this.handleModalClose()
        .then( () => {
          return new Promise((resolve) => this.setState({modalType: typeOfModal}, () => resolve()));
        })
    }
  }

  handleModalOpen = ( obj ) => {
    console.log("handleModalOpen", obj)
    if(obj === undefined) {
      return new Promise((resolve) => this.setState({isModalOpen: true}, () => resolve()));
    } else {
      var modalValue = this.state.modalValue;
      switch( this.state.modalType ) {
        case 'tile_edit':
          modalValue[this.state.modalType] = Object.assign({}, modalValue[this.state.modalType], obj);
          return new Promise((resolve) => this.setState({isModalOpen: true, "modalValue": modalValue}, () => resolve()));
        default:
          return new Promise((resolve) => this.setState({isModalOpen: true}, () => resolve()));
      }
    }
  }

  deleteRow = ( rowNum ) => {
    var tileData = this.state.tileData;
    tileData.splice(rowNum, 1);
    return new Promise((resolve) => this.setState({tileData: tileData}, () => resolve()));
  }

  editRowType = (rowNum) => {
    var tileData = this.state.tileData;
    switch(tileData[rowNum].type) {
      case 'set':
        tileData[rowNum].type = 'line';
        break;
      case 'line':
        tileData[rowNum].type = 'set';
        break;
      default:
        break;
    }

    return new Promise((resolve) => this.setState({tileData: tileData}, () => resolve()));
  }

  addNewRow = () => {
    var tileData = this.state.tileData;
    var newRow = {
      type: 'line',
      data: []
    }
    tileData.push(newRow)
    return new Promise((resolve) => this.setState({tileData: tileData}, () => resolve()));
  }

  addNewTile = (rowNum) => {
    var tileData = this.state.tileData;
    tileData[rowNum].data.push({ value: 0, name: ("Tile #" + (tileData[rowNum].data.length + 1)) })
    return new Promise((resolve) => this.setState({tileData: tileData}, () => resolve()));
  }

  toggleEditingMode = () => {
    var editingMode = this.state.editMode;
    return new Promise((resolve) => this.setState({editMode: !editingMode}, () => resolve(!editingMode)));
  }

  deleteTile = (rowNum, tileNum) => {
    var tileData = this.state.tileData;
    tileData[rowNum].data.splice(tileNum, 1);
    return new Promise((resolve) => this.setState({tileData: tileData}, () => resolve()));
  }

  editTile = ( rowNum, tileNum, data ) => {
    console.log("editTile", `( ${rowNum}, ${tileNum}, ${JSON.stringify(data)} )`)
    var tileData = this.state.tileData;
    var tileObj = tileData[rowNum].data[tileNum];
    tileObj[data.type] = data.value;
    console.log("editTile - tileObj", tileObj)
    return new Promise((resolve) => this.setState({tileData: tileData}, () => resolve()));
  }

  getData(datasourceName) {
    var modalValue = this.state.modalValue;
    var datasourceIndx = dataSources.map(obj => obj.name).indexOf(datasourceName)
    var API_DETAILS = dataSources[datasourceIndx]
    if(API_DETAILS === undefined) {
      throw new Error("JigError/API_NOT_FOUND")
    }
    var API_DATE_FMT = API_DATE_FORMAT[API_DETAILS.version];
    var startDate = moment(this.state.startDate).startOf('day').format(API_DATE_FMT) || moment().startOf('week').format(API_DATE_FMT)
    var endDate = moment(this.state.endDate).endOf('day').format(API_DATE_FMT) || moment().endOf('week').format(API_DATE_FMT)
    var axiosRequestObject = {
      method: 'get',
      url: API_DETAILS.url,
      responseType: 'json'
    }

    axiosRequestObject.url = axiosRequestObject.url.replace("startDate", startDate);
    axiosRequestObject.url = axiosRequestObject.url.replace("endDate", endDate);


    return axios(axiosRequestObject)
      .then(function(response) {
        console.log("getData", response.data)
        if(response.status === 200)
          return response.data;
        else
          throw response.data.error;
      })
      .then(newData => {
        console.log("newData", newData)
        //TODO: Hacky, only works if the getData function is called inside the currently selected/opened modal
        modalValue[this.state.modalType].data = newData.data;
        return new Promise((resolve) => this.setState({"modalValue": modalValue}, () => resolve()));
      })
      .catch(function(error){
        //TODO: Send back to motherbase!
        console.error(error);
        throw error;
      })
  }

  openEditTileModal = (rowNum, tileNum) => {
    /*var tileData = this.state.tileData;
    var tileObj = tileData[rowNum].data[tileNum];
    tileObj.name = "Tile ###";
    tileObj.value = 99;
    this.setState({ tileData: tileData })*/
    return this.setModalType("tile_edit")
      .then(() => {
        return this.handleModalOpen({ 'rowNum': rowNum, 'tileNum': tileNum });
      })
  }

  openDemoModal = () => {
    return this.setModalType("demo")
      .then(() => {
        return this.handleModalOpen();
      })
  }

  openDatasourceModal = () => {
    return this.setModalType("datasources")
      .then(() => {
        return this.handleModalOpen();
      })
  }

  editDashboardName = () => {
    var result = prompt("dashboard name", this.state.name)
    if(!result) { result = this.state.name }
    return new Promise((resolve) => this.setState({name: result}, () => resolve()));
  }

  handleModalClose = (value) => {
    var modalValue = this.state.modalValue;
    modalValue[this.state.modalType] = value;
    console.log("handleModalClose", value)
    if(modalValue === undefined) {
      return new Promise((resolve) => this.setState({isModalOpen: false, modalType: null}, () => resolve()));
    } else {
      return new Promise((resolve) => this.setState({isModalOpen: false, modalValue: modalValue, modalType: null}, () => resolve()));
    }
  }

  handleModalChange = () => {
    switch( this.state.modalType ) {
      case 'tile_edit':
        return this.editTile;
      default:
        return this.handleModalEventChange;
    }
  }

  handleModalEventChange = (event) => {
    var modalValue = this.state.modalValue;
    modalValue[this.state.modalType] = event.target.value
    console.log("event", event.target.value)
    return new Promise((resolve) => this.setState({modalValue: modalValue}, () => resolve()));
  }

  saveDashboardData = () => {
    var dashboardData = Object.assign({}, {
      data: this.state.tileData,
      id: this.state.id,
      name: this.state.name,
      datasources: this.state.modalValue.datasources,
      period: this.state.selectedPeriod,
      refreshInterval: this.state.refreshInterval
    });
    console.log("dashboardData.data data", this.state.tileData)
    //Set all data to 0 or whatever is neutral
    dashboardData.data.map((rowData, rowIndx) => {
      return rowData.data.map((y, tileIndx) => {
        console.log("y = ", y)
        switch(y.value_type) {
          default:
            y.value = 0;
        }
        console.log("dashboardData splice check", y.filters, y.api_name, y.value_type, y.value_name, (y.filters === undefined || y.api_name === undefined || y.value_type === undefined || y.value_name === undefined))
        if(y.filters === undefined || y.api_name === undefined || y.value_type === undefined || y.value_name === undefined) {
          dashboardData.data[ rowIndx ].data.splice(tileIndx, 1)
        }
        return y;
      })
    })
    var dashboardStringData = JSON.stringify(dashboardData);
    console.log("dashboardData", dashboardStringData)
    return database.ref(`${dashboardDataRef}/${dashboardData.id}`).set(dashboardStringData)
      .then(() => {
        return this.navigateToDashboardHome()
      })
  }

  modalContentGenerator = (type, testVal, handleChangeFunction) => {
    console.log("modalContentGenerator", type, testVal)
    switch(type) {
      case 'tile_edit':
        console.log("testVal", testVal)
        if(testVal === undefined)
          return null;
        let modalVal = this.state.modalValue[type];
        console.log(type, this.state.modalValue)
        let rowNum = modalVal.rowNum;
        let tileNum = modalVal.tileNum;
        let modalData = testVal.data || [];
        console.log("modalData", JSON.stringify(modalData)) //modalData = getData response
        let tmpModalKeys = modalData.map((item, i) => { return Object.keys(item) })
        let tmpModalValues = modalData.map((item, i) => {
          if(this.state.tileData[rowNum].data[tileNum].filters) {
            return this.state.tileData[rowNum].data[tileNum].filters.map((filterObj, filterIndx) => {
              return item[filterObj.key]
            })
          } else {
            return Object.keys(item);
          }
        })
        var modalKeys = []
        var modalValues = []
        var modalNumbers = []
        tmpModalKeys.map( (arr, i) => {
          return arr.map((arrItem, j) => {
            if(['data'].includes(arrItem)) return null;
            if(typeof(modalData[i][arrItem]) !== "string") {
              if( typeof(modalData[i][arrItem]) === "number" && modalNumbers.indexOf(arrItem) === -1 ) {
                console.log(typeof(modalData[i][arrItem]), arrItem, modalData[i][arrItem])
                modalNumbers.push(arrItem)
              } else {
                return null;
              }
            }
            if(modalKeys.indexOf(arrItem) === -1) {
              modalKeys.push(arrItem)
            }
            return null;
          })
        })

        tmpModalValues.map( (arr, i) => {
          return arr.map((arrItem, j) => {
            if(typeof(arrItem) !== "string") return null;
            if(modalValues.indexOf(arrItem) === -1) {
              modalValues.push(arrItem)
            }
            return null;
          })
        })

        var allFiltersAreValid = (filterArray) => {
          var truthy = false;
          filterArray.map((item, i) => {
            if( item.key !== '' && item.value !== '' ) {
              truthy = true;
            } else {
              truthy = false;
            }
            return truthy;
          })
          return truthy;
        }

        var filterArrayByArrayOfFilters = (array, filters) => {
          const filterKeys = Object.keys(filters);
          return array.filter(item => {
            // validates all filter criteria
            return filterKeys.every(key => {
              // ignores non-function predicates
              if (typeof filters[key] !== 'function') return true;
              return filters[key](item[key]);
            });
          });
        }

        var finalValue = [];
        if(this.state.tileData[rowNum].data[tileNum].filters && allFiltersAreValid(this.state.tileData[rowNum].data[tileNum].filters)) {
          var localFilters = {};
          this.state.tileData[rowNum].data[tileNum].filters.map(x => {
            let chkVal = (valueToCheck) => {
              //console.log("valueToCheck", valueToCheck)
              return valueToCheck === x.value
            }

            if(x.key === 'date') {
              var dates = []
              //TODO: Date format parsing v1/v2 switching based on API
              for(let i = moment(this.state.startDate).startOf('day'); moment(i).isBefore(this.state.endDate); i.add(1, 'days')) {
                dates.push( moment(i).startOf('day').format("YYYY-MM-DD") )
              }

              dates = dates.sort().reverse()
              //console.log("x.value", x.value)
              var dateChecks = []
              x.value.map(dateIndx => {
                return dateChecks.push({ startDate: moment(dates[dateIndx]).startOf('day'), endDate: moment(dates[dateIndx]).endOf('day') })
              })
              //console.log("x.value - dateChecks", dateChecks)

              chkVal = (valueToCheck) => {
                var val = moment(valueToCheck, "YYYY-MM-DD").startOf('day');
                var dateCheck = Object.assign([], dateChecks)
                //console.log("dateCheck", dateCheck)
                var truthy = false;
                dateCheck.map(x => {
                  //console.log("valueToCheck", val.valueOf(), `isBetween(${ moment(x.startDate).valueOf() }, ${ moment(x.endDate).valueOf() }) = ${moment(val).isBetween(x.startDate, x.endDate, null, "[]")}`)
                  if(moment(val).isBetween(x.startDate, x.endDate, null, "[]")) {
                    truthy = true;
                  }
                  return truthy;
                })
                return truthy;
              }
            }
            return localFilters[ x.key ] = chkVal;
          })
          console.log("localFilters", localFilters)
          finalValue = filterArrayByArrayOfFilters( modalData , localFilters )
        }

        var reduceByItem = (key, type) => {
          console.log(`reduceByItem(${key}, ${type})`)
          return finalValue.reduce((total, obj) => {
            switch(type) {
              case 'currency':
                return parseFloat(total + parseFloat( parseFloat(obj[key]/100).toFixed(2) ));
              default:
                return total + obj[key];
            }
          }, 0)
        }

        var formatNumber = ( item, formatStr ) => {
          var num = reduceByItem(item, formatStr);
          switch(formatStr) {
            case 'currency':
             return '$'+num;
            default:
             return num;
          }
        }

        var filterValuesByKey = (value, keyName) => {
          var truthy = false;
          modalData.map((item, i) => {
            if( item[ keyName ] === value ) {
              truthy = true;
            }
            return truthy;
          })
          return truthy;
        }

        console.log("finalValue", finalValue)
        console.log("modalKeys", modalKeys)
        console.log(rowNum, tileNum, this.state.tileData[rowNum])
        console.log("this.state.tileData[rowNum].data[tileNum]", this.state.tileData[rowNum].data[tileNum])
        return (
          <Paper style={{ position: 'absolute', left: "0", right: "0", margin: '0px auto', maxWidth: 1080, border: '2px solid #000'}}>
            <h2 id="simple-modal-title">Editing tile</h2>
            <div style={{display: 'flex', flexDirection: 'row', padding: '2rem 1rem'}}>
              <TextField
                id="tile-name-edit"
                label={'Tile name'}
                value={this.state.tileData[rowNum].data[tileNum].name}
                onChange={(event) => handleChangeFunction(rowNum, tileNum, { type: 'name', value: event.target.value })}
              />
            </div>
            {
              this.state.modalValue.datasources.length > 0 ? (
                <div style={{display: 'flex', flexDirection: 'column', padding: '2rem 1rem'}}>
                  <InputLabel id="select-api-label">Select an API</InputLabel>
                  <Select
                    labelId="select-api-label"
                    id="select-api-1"
                    value={this.state.tileData[rowNum].data[tileNum].api_name ? this.state.tileData[rowNum].data[tileNum].api_name : ''}
                    onChange={(event) => {
                      if(handleChangeFunction !== undefined) {
                        return handleChangeFunction(rowNum, tileNum, { type: 'api_name', value: event.target.value })
                          .then(() => {
                            return this.state.tileData[rowNum].data[tileNum].api_name !== undefined ? this.getData(event.target.value) : null;
                          })
                      }
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Select an API</em>
                    </MenuItem>
                    {this.state.modalValue.datasources.map(sourceObj => (
                      <MenuItem key={ 'api_select_option_' + sourceObj} value={sourceObj}>
                        {sourceObj}
                      </MenuItem>
                    ))}
                  </Select>
                  <div style={{display: 'inline-flex', flexDirection: 'column', width: '100%', padding: '2rem 1rem'}}>
                  {/*
                    modalKeys.length > 0 ? (
                      <div style={{display: 'inline-flex', flexDirection: 'column', width: '100%'}}>
                        <h3>Step 2: Filter by key:</h3>
                        {
                          modalKeys.map((item, i) => {
                            return (
                              <div onClick={() => this.editTile(rowNum, tileNum, { type: 'filter', value: item })} style={{display: 'inline-flex', flexDirection: 'row', width: '100%'}} key={"filter_data_" + i}>{item}</div>
                            )
                          })
                        }
                      </div>
                    ) : null
                   */}
                   {
                    this.state.tileData[rowNum].data[tileNum].filters !== undefined ? (
                      this.state.tileData[rowNum].data[tileNum].filters.map((filterObj, filterIndx) => {
                        return (
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}} key={'filter_' + filterIndx}>
                              <div style={{display: 'inline-flex', flexDirection: 'column', width: '40%'}}>
                                <SelectKeyComponent
                                  rowNum={ rowNum }
                                  tileNum={ tileNum }
                                  filterObj={ filterObj }
                                  filterIndx={ filterIndx }
                                  filters={ this.state.tileData[rowNum].data[tileNum].filters }
                                  handleChangeFunction={ handleChangeFunction }
                                  modalKeys={ modalKeys }
                                />
                              </div>
                              <div style={{display: 'inline-flex', flexDirection: 'column', width: '40%'}}>
                                <InputLabel id={`select-filters-value-label-${filterIndx}`}>Key Value</InputLabel>
                                <Select
                                  labelId={`select-filters-value-label-${filterIndx}`}
                                  id={`select-filters-value-${filterIndx}`}
                                  multiple={ filterObj.key ? ( filterObj.key === 'date' ? true : false ) : false }
                                  value={(() => {
                                    console.log(filterObj, filterObj.value)
                                    return filterObj ? (
                                      (filterObj.key === 'date' ? ( filterObj.value === "" ? [] : filterObj.value ) : filterObj.value)
                                    ) : []
                                  })()}
                                  input={<Input id={`select-multiple-values-${filterIndx}`} />}
                                  renderValue={selected => {
                                    console.log("selecteddd", selected)

                                    if(filterObj.key && filterObj.key === 'date') {
                                        var dates = []
                                        //TODO: Date format parsing v1/v2 switching based on API
                                        for(let i = moment(this.state.startDate).startOf('day'); moment(i).isBefore(this.state.endDate); i.add(1, 'days')) {
                                          dates.push( moment(i).startOf('day').format("YYYY-MM-DD") )
                                        }

                                        dates = dates.sort().reverse()

                                        var getLabelValue = (value) => {
                                          var valueToDisplay = dates[value];
                                          var curDate = moment().startOf('day');
                                          var newDate = moment(dates[value], "YYYY-MM-DD").startOf('day')
                                          if(curDate.valueOf() === newDate.valueOf()) {
                                            valueToDisplay = 'Today - ' + newDate.format("YYYY-MM-DD")
                                          } else {
                                            valueToDisplay = newDate.from( curDate ) + ' - ' + newDate.format("YYYY-MM-DD")
                                          }
                                          return valueToDisplay;
                                        }
                                      return (
                                        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                                          {selected.map(value => (
                                            <Chip key={value} label={getLabelValue(value)} style={{ margin: 2  }} />
                                          ))}
                                        </div>
                                      )
                                    }

                                    return (
                                      <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                                        <Chip key={selected} label={selected} style={{ margin: 2  }} />
                                      </div>
                                    )
                                  }}
                                  onChange={(event) => {
                                    console.log("onChange", event.target)
                                    var theData = this.state.tileData[rowNum].data[tileNum].filters;
                                    if(theData === undefined || theData === null) {
                                        theData = []
                                    }

                                    if(!theData[filterIndx].value) {
                                      theData[filterIndx].value = []
                                    }

                                    if(filterObj.key === 'date') {
                                      console.log("event.target.value", event.target.value)
                                      theData[filterIndx].value = event.target.value
                                    } else {
                                      console.log("event.target", event.target, filterObj)
                                      theData[filterIndx].value = event.target.value
                                    }
                                    console.log("updating filters...", theData[filterIndx], event.target.value, event)
                                    return handleChangeFunction(rowNum, tileNum, { type: 'filters', value: theData })
                                  }}
                                  MenuProps={{
                                    maxheight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                  }}
                                  >
                                    {
                                      modalValues !== undefined ? (() => {
                                        if(filterObj) {
                                          if(filterObj.key === 'date') {
                                            modalValues.sort((a, b) => {
                                              if(a < b) {
                                                return 1;
                                              } else if (a > b) {
                                                return -1;
                                              } else {
                                                return 0;
                                              }
                                            })
                                          }
                                        }
                                        return modalValues
                                          .filter(x => {
                                            if(filterObj) {
                                              return filterValuesByKey(x, filterObj.key)
                                            } else {
                                              return x;
                                            }
                                          })
                                          .map(sourceObj => {
                                            //TODO: Better date checking and formatting
                                            let valueToDisplay = sourceObj;
                                            /*
                                              TODO: Using date period (last X days/weeks/months), ask to filter date period further
                                                example:
                                                  Last X months
                                                  Last X weeks
                                                  Last X days
                                                  Today
                                            */

                                            if(filterObj.key === 'date') {
                                              var dates = []
                                              //TODO: Date format parsing v1/v2 switching based on API
                                              for(let i = moment(this.state.startDate).startOf('day'); moment(i).isBefore(this.state.endDate); i.add(1, 'days')) {
                                                dates.push( moment(i).startOf('day').format("YYYY-MM-DD") )
                                              }

                                              dates = dates.sort().reverse()

                                              var arrIndx = dates.indexOf(sourceObj)
                                              //console.log("dates", dates, arrIndx, sourceObj)
                                              if( arrIndx !== -1) {
                                                var curDate = moment().startOf('day');
                                                var newDate = moment(sourceObj, "YYYY-MM-DD").startOf('day')
                                                if(curDate.valueOf() === newDate.valueOf()) {
                                                  valueToDisplay = 'Today - ' + sourceObj
                                                } else {
                                                  valueToDisplay = newDate.from( curDate ) + ' - ' + sourceObj
                                                }
                                              }
                                            }

                                            return (
                                              <MenuItem key={ 'filters_select_value_option_' + valueToDisplay} value={filterObj.key === 'date' ? arrIndx : valueToDisplay}>
                                                {valueToDisplay}
                                              </MenuItem>
                                            )
                                          })
                                      })(): null}
                                </Select>
                              </div>
                              <div style={{display: 'inline-flex', flexDirection: 'column', width: '20%'}}>
                                <Button
                                  style={{marginTop: 'auto'}}
                                  onClick={() => {
                                    var theData = this.state.tileData[rowNum].data[tileNum].filters;
                                    if(theData === undefined || theData === null) {
                                      return null;
                                    } else {
                                      theData.splice(filterIndx, 1)
                                      return this.editTile(rowNum, tileNum, { type: 'filters', value: theData })
                                    }
                                  }}
                                >Delete Filter</Button>
                              </div>
                            </div>
                        )
                      })
                    ) : null
                    }
                    <div style={{display: 'inline-flex', flexDirection: 'row', width: '100%', padding: '2rem 0px'}}>
                      <Button
                        onClick={() => {
                          var theData = this.state.tileData[rowNum].data[tileNum].filters;
                          if(theData === undefined || theData === null) {
                            theData = []
                          }
                          theData.push({ 'key': '', 'value': '' })
                          return this.editTile(rowNum, tileNum, { type: 'filters', value: theData })
                        }}
                      >Add Filter</Button>
                    </div>
                   {/* TODO: Add [+] Filter, what if we want to filter by created_by_name=person & status_type=won */}
                   {/*
                     this.state.tileData[rowNum].data[tileNum].filter !== undefined ? (
                       <div style={{display: 'inline-flex', flexDirection: 'column', width: '100%'}}>
                         <h3>Step 3: Filter by value:</h3>
                         {
                           modalValues.map((item, i) => {
                             return (
                               <div onClick={() => this.editTile(rowNum, tileNum, { type: 'filter_value', value: item })} style={{display: 'inline-flex', flexDirection: 'row', width: '100%'}} key={"filter_value_data_" + i}>{item}</div>
                             )
                           })
                         }
                       </div>
                     ) : null
                   */}
                   {
                     (
                       this.state.tileData[rowNum].data[tileNum].filters !== undefined
                        && this.state.tileData[rowNum].data[tileNum].api_name !== undefined
                        && allFiltersAreValid(this.state.tileData[rowNum].data[tileNum].filters)
                     ) ? (
                      <div style={{display: 'inline-flex', flexDirection: 'row', width: '50%'}}>
                         <h3>Reduce by:</h3>
                         {
                           modalNumbers.map((item, i) => {
                             return (
                               <Button
                                onClick={() => this.editTile(rowNum, tileNum, { type: 'value_name', value: item })}
                                style={{display: 'inline-block', width: `${parseFloat( ( 1 / modalNumbers.length) ).toFixed(2)}%`}}
                                key={"value_data_" + i}
                               >{item}</Button>
                             )
                           })
                         }
                      </div>
                     ) : null
                   }
                   {
                     (
                       this.state.tileData[rowNum].data[tileNum].filters !== undefined
                        && this.state.tileData[rowNum].data[tileNum].api_name !== undefined
                        && allFiltersAreValid(this.state.tileData[rowNum].data[tileNum].filters)
                        && this.state.tileData[rowNum].data[tileNum].value_name !== undefined
                     ) ? (
                      <div style={{display: 'inline-flex', flexDirection: 'row', width: '50%'}}>
                         <h3>Final step: Select format to display</h3>
                         {
                           modalNumbers.filter(x => x === this.state.tileData[rowNum].data[tileNum].value_name ).map((item, i) => {
                             //console.log("YEAH item", item)
                             return ['number', 'currency'].map((formatString, j) => {
                               return (
                                 <Button
                                  onClick={() => {
                                    return this.editTile(rowNum, tileNum, { type: 'value', value: reduceByItem(item, formatString) })
                                      .then(() => {
                                        return this.editTile(rowNum, tileNum, { type: 'value_type', value: formatString })
                                      })
                                      .then(() => {
                                        return this.handleModalClose(testVal)
                                      })
                                  }}
                                  style={{display: 'inline-block', width: `${parseFloat( ( 1 / modalNumbers.length) ).toFixed(2)}%`}}
                                  key={"format_value_data_" + i + '_' + j}
                                 >{`${formatNumber(item, formatString)}`}</Button>
                               )
                             })
                           })
                         }
                      </div>
                     ) : null
                   }
                  </div>
                </div>
              ) : null
            }
          </Paper>
        );
      case 'demo':
        return (
          <Paper style={{ position: 'absolute', left: "0", right: "0", margin: '0px auto', maxWidth: 720, border: '2px solid #000'}}>
            <h2 id="simple-modal-title">{type} - {JSON.stringify(this.state.modalValue['datasources'])}</h2>
          </Paper>
        );
      case 'datasources':
        return (
          <Paper style={{ display: 'flex', flexDirection: 'column', position: 'absolute', left: "0", right: "0", margin: '0px auto', maxWidth: 720, border: '2px solid #000'}}>
            <h2 id="simple-modal-title">Edit datasources</h2>
            <FormControl style={{ display: 'inline-flex', flexDirection: 'column', width: '50%', maxWidth: 600, margin: '2rem auto'}}>
              <InputLabel id="demo-mutiple-chip-label">Pick a datasource</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={testVal}
                onChange={(event) => handleChangeFunction(event)}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => {
                  console.log("selected", selected)
                  return (
                    <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                      {selected.map(value => (
                        <Chip key={value} label={value} style={{ margin: 2  }} />
                      ))}
                    </div>
                  )
                }}
                MenuProps={{
                  maxheight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  width: 250,
                }}
              >
                {dataSources.map(source => (
                  <MenuItem key={source.name} value={source.name}>
                    {source.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        );
      default:
        return (
          <Paper style={{ position: 'absolute', left: "0", right: "0", margin: '0px auto', width: 400, height: 400, border: '2px solid #000'}}>
            <h2>Invalid modal type! Click anywhere to close</h2>
          </Paper>
        )
    }
  }

  getModalValue = () => {
    if(this.state.modalType === null) {
      return null;
    } else {
      //console.log(`this.state.modalValue[${this.state.modalType}]`, this.state.modalValue[this.state.modalType], this.state.modalValue)
      return this.state.modalValue[this.state.modalType]
    }
  }

  navigateToDashboardHomeConfirm = () => {
    if(window.confirm("You have unfinished changes to your dashboard\n\nClick OK to lose all changes (To save, look at the top right toolbar, click 'Save & Exit')")) {
      return this.props.history.push('/dashboards')
    } else {
      return null;
    }
  }

  navigateToDashboardHome = () => {
    return this.props.history.push('/dashboards')
  }

  formatOdometer = ( valueType ) => {
    console.log("valueType", valueType)
    switch(valueType) {
      case 'currency':
        return 'odometer-currency'
      default:
        return '';
    }
  }

  render() {
    var editMode = this.state.editMode;
    console.log("this.state.tileData", JSON.stringify(this.state.tileData))
    return (
      <Fragment>
        <AppNavBar
          showBackBtn={true}
          onBackBtnClickAction={() => this.navigateToDashboardHomeConfirm()}
        />
        <div className="dashboard-app">
          <div className="dashboard-creator-toolbar">
            <div className="dashboard-creator-icon" onClick={() => this.addNewRow()} style={{margin: '0px 0px 0px 0px'}}>
              <p>New Row</p>
              <AddBox style={{ color: '#1abc9c', margin: 'auto auto auto 0.25rem' }} />
            </div>
            <div className="dashboard-creator-icon" onClick={() => this.editDashboardName()} style={{margin: '0px 1rem 0px 1rem'}}>
              <p style={{ fontWeight: 'bold' }}>{this.state.name}</p>
              <EditIcon style={{ color: '#1abc9c', margin: 'auto auto auto 0.25rem' }} />
            </div>
            <div className="dashboard-creator-icon" onClick={() => this.setState({ openRefreshIntervalSelect: !this.state.openRefreshIntervalSelect })} style={{margin: '0px auto 0px 0px'}}>
              <p>Refresh Interval: </p>
              <Select
                id={`select-refresh-interval`}
                value={this.state.refreshInterval}
                onChange={(event) => {
                  console.log("refreshInterval", event.target.value)
                  return this.editRefreshInterval(event.target.value)
                }}
                style={{color: 'white', minWidth: '64px'}}
                open={this.state.openRefreshIntervalSelect}
                >
                  <MenuItem disabled value="">
                    <em>Select an Interval</em>
                  </MenuItem>
                  {
                    this.state.refreshTimes.map(sourceObj => (
                      <MenuItem key={ 'filters_select_key_option_' + sourceObj.value} value={sourceObj.value}>
                        {sourceObj.value}
                      </MenuItem>
                    ))
                  }
              </Select>
            </div>
            <div className="dashboard-creator-icon" style={{margin: '0px 1rem 0px 0rem'}} onClick={() => this.setState({ openPeriodSelect: !this.state.openPeriodSelect })}>
              <p>Period: </p>
              <Select
                id={`select-period-1`}
                value={this.state.selectedPeriod}
                onChange={(event) => {
                  //console.log("thePeriod", event.target.value)
                  return this.editPeriod(event.target.value)
                }}
                style={{color: 'white', minWidth: '64px'}}
                open={this.state.openPeriodSelect}
                >
                  <MenuItem disabled value="">
                    <em>Select a Period</em>
                  </MenuItem>
                  {
                    this.state.periodTimes.map(sourceObj => (
                      <MenuItem key={ 'filters_select_key_option_' + sourceObj.value} value={sourceObj.value}>
                        {sourceObj.value}
                      </MenuItem>
                    ))
                  }
              </Select>
            </div>
            <div className="dashboard-creator-icon" onClick={() => this.openDatasourceModal()}>
              <p>Edit Datasource</p>
              <EditIcon style={{ color: '#1abc9c', margin: 'auto auto auto 0.25rem' }} />
              {/*TODO: Tooltip with datasource*/}
            </div>
            <div className="dashboard-creator-icon" onClick={() => this.saveDashboardData()}>{/*this.toggleEditingMode()}>*/} {/*this.openDemoModal()*/}
              <p>Save & Exit</p>
              <CheckIcon style={{ color: '#1abc9c', margin: 'auto auto auto 0.25rem' }} />
            </div>
          </div>
          <div className="dashboard-container">
            <div className="metrics-container">
              <div className="metrics-scroll-container">
              {
                this.state.tileData.map((tileRow, rowIndx) => {
                  return (
                    <div className={`metric-row ${tileRow.type}`} key={'metric_tile_row_' + rowIndx}>
                      {
                          tileRow.data.map((tileObj, tileIndx) => {
                            return (
                              <div className='metric-tile' style={{ cursor: (editMode ? 'all-scroll' : null ), position: 'relative' }} key={'metric_tile_row' + rowIndx + '_tile_' + tileIndx}>
                                <div className={'metric-tile-value ' + this.formatOdometer(tileObj.value_type)}>
                                  <Odometer value={tileObj.value}/>
                                </div>
                                <b className={'metric-tile-title'}>{tileObj.name}</b>
                                  {
                                    this.state.editMode ? (
                                      <div className="metric-tile-edit-icon" onClick={() => this.openEditTileModal(rowIndx, tileIndx)}>
                                        <EditIcon style={{ position: 'absolute', width: '2rem', height: '2rem', right: '0.5rem', top: '0.5rem' }} />
                                      </div>
                                    ): null
                                  }
                                  {
                                    this.state.editMode ? (
                                      <div className="metric-tile-delete-icon" onClick={() => this.deleteTile(rowIndx, tileIndx)}>
                                        <DeleteIcon style={{ position: 'absolute', width: '2rem', height: '2rem', right: '0.5rem', top: '0.5rem' }} />
                                      </div>
                                    ): null
                                  }
                              </div>
                            );
                          })
                      }
                      {
                        this.state.editMode ? (
                          <div className='metric-tile' style={{width: '20%', height: '100%', margin: 'auto', backgroundColor: 'unset'}}>
                            <div className="new-tile-btn" onClick={() => this.deleteRow(rowIndx)}>
                              <DeleteIcon style={{ color: '#e74c3c', width: '30%', height: '30%', margin: 'auto' }} />
                            </div>
                            <div className="new-tile-btn" onClick={() => this.editRowType(rowIndx)}>
                              <EditIcon style={{ color: '#f1c40f', width: '30%', height: '30%', margin: 'auto' }} />
                            </div>
                            <div className="new-tile-btn" onClick={() => this.addNewTile(rowIndx)}>
                              <AddBox style={{ color: '#1abc9c', width: '30%', height: '30%', margin: 'auto' }} />
                            </div>
                          </div>

                        ) : null
                      }
                      </div>
                  )
                })
              }
              </div>
            </div>
          </div>
          <ModalHandler
            type={this.state.modalType}
            open={this.state.isModalOpen}
            handleClose={this.handleModalClose}
            modalContent={this.modalContentGenerator}
            handleModalChange={this.handleModalChange()}
            testVal={this.getModalValue()}
          />
        </div>
      </Fragment>
    );
  }
}

/*
  <div className={'metric-tile-value'}>
    <Odometer value={0}/>
  </div>
  <b className={'metric-tile-title'}>Value Name</b>
*/
const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(DashboardGenScreen);
