import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { isProduction } from 'datastore/constants';
import { withStyles } from '@material-ui/core/styles';
import { updateDates } from 'actions';
//import FancyBar from 'components/FancyBarChart';
import OptionsActionBar from 'components/OptionsActionBar';
import AppNavBar from 'components/AppNavBar';
import moment from 'moment';
import axios from 'axios';
import Papa from 'papaparse';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import './index.css';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip'
const API_URL = "https://reporting.brhapps.com/reports/"

const useStyles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
});

const marks = [
  {
    value: 1,
    label: '1 day',
  },
  {
    value: 7,
    label: '7 days',
  },
  {
    value: 30,
    label: '30 days',
  },
  {
    value: 31,
    label: '31 days',
  }
];

var periodTime = ["agent-bvb-report-period", "tech-bvb-report-period", "scheduled-duration-bvb-report-period"];

function valuetext(value) {
  return `${value} day${value === 1 ? '' : 's'}`;
}

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={valuetext(value)}
    >
      {children}
    </Tooltip>
  );
}

const centerDivStyle = {display: 'flex', flexDirection: 'column', alignItems: 'center'};

class ReportGenScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      steps: ["Step 1", "Step 2"],
      valueSlider: 1,
      csvData: [],
      csvRaw: null,
      reportType: null,
      readyToDownload: false,
      report_id: null,
      downloadPDFisPending: false,
      hideHours: false
    }
  }

  setActiveStep = (newValue) => {
    this.setState({ activeStep: newValue })
  }

  handleNext = () => {
    const activeStep = this.state.activeStep;
    this.setActiveStep( (activeStep + 1) );
  };

  handleBack = () => {
    const activeStep = this.state.activeStep;
    this.setActiveStep( (activeStep - 1) );
  };

  handleReset = () => {
    this.setState({ reportType: null, activeStep: 0, sliderValue: 1, hideHours: false })
    return this.props.updateDates(null, null)
  };

  componentDidMount() {
    this.fetchData()
  }

  checkActiveStep = ( activeStep ) => {
    //TODO: Check if dates were selected correctly!
    /*
    const { startDate, endDate } = this.props;
    if(startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
        return this.fetchData()
    }
    */

    switch( activeStep ) {
      case 2:
        return this.fetchData()
      default:
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if( prevState.activeStep !== this.state.activeStep ) {
      return this.checkActiveStep( this.state.activeStep )
    }
  }

  fetchData = () => {
    var startDt = this.props.startDate;
    var endDt = this.props.endDate;

    if(startDt != null && endDt != null) {
      console.log("Request send", startDt, endDt, this.state.reportType)
      this.setState({ csvData: [], csvRaw: null, readyToDownload: false, report_id: null, downloadPDFisPending: false })
      var startDate = moment(startDt).startOf('day').format("YYYY-MM-DD");
      var endDate = moment(endDt).endOf('day').format("YYYY-MM-DD");
      var reportType = this.state.reportType;
      console.log("Test", startDt, endDt)


      var axiosRequestObject = {
        method: 'post',
        url: API_URL + reportType,
        data: {
          'startDate': startDate,
          'endDate': endDate
        },
        responseType: 'json'
      }

      return axios( axiosRequestObject )
        .then(response => {
          console.log("test", response)
          var requestData = response.data;
          this.setState({ report_id: requestData.id })
          return requestData.data;
        })
        .then( data => {
          if(data) {
            var csv = Papa.unparse(data, { columns: ["id", "jobType", "isGoodwill", "isBlowout", "customer_id", "created_by_name", "planned_duration", "actual_duration", "worker_id", "steps", "status", "customer_name", "worker_name", "planned_start_at", "planned_end_at", "actual_start_at", "actual_end_at", "created_at", "updated_at", "third_party_id", "dateId", "techCode", "billedHours"] });
            var ohnana = Papa.parse(csv);
            console.log("ohnana", ohnana.data, data, csv)
            this.setState({ csvRaw: csv, csvData: ohnana.data, readyToDownload: true })
          } else {
            console.log("Show an error?")
          }
        })
        .catch(err => {
          console.log("[ReportingScreen]", err)
          return true;
        })
    } else {
      //console.log("what the hell")
      return new Promise(() => { return false })
    }
  }

  navigateToHome = () => {
    return this.props.history.push( '/home' )
  }

  handleReportSelection = (event) => {
    this.setState({ reportType : event.target.value })
  }

  getStepContent = ( stepIndex ) => {
    const { classes } = this.props;

    switch (stepIndex) {
      case 0:
        return (
          <div style={{display: 'inline-flex', flexDirection: 'column'}}>
            <h2 style={{margin: 'auto auto auto 1rem', display: 'inline-block'}}>Select a date range</h2>
            <div style={{margin: 'auto', display: 'inline-block'}}>
              <OptionsActionBar />
            </div>
          </div>
        );
      case 1:
        return (
          <div style={{display: 'inline-flex', flexDirection: 'column', width: '100%'}}>
            <h2 style={{margin: 'auto auto auto 1rem', display: 'inline-block'}}>Select a report</h2>
            <div style={{margin: 'auto', paddingTop: '2rem', paddingBottom: '2rem', display: 'inline-block', width: '70%', height: 'auto'}}>
              <form className={classes.root} autoComplete="off">
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-reportType-simple">Report</InputLabel>
                    <Select
                      value={this.state.reportType || ''}
                      onChange={this.handleReportSelection}
                      inputProps={{
                        name: 'reportType',
                        id: 'outlined-reportType-simple',
                      }}
                    >
                      <MenuItem value={''}>
                          <em>None</em>
                      </MenuItem>
                      <MenuItem value={'tech-bvb-report'}>BvB Technicans - List</MenuItem>
                      <MenuItem value={'agent-bvb-report-period'}>BvB Sales Agents - Period</MenuItem>
                      <MenuItem value={'tech-bvb-report-period'}>BvB Technicians - Period</MenuItem>
                      <MenuItem value={'scheduled-duration-bvb-report-period'}>BvB Scheduled Duration - Period</MenuItem>
                    </Select>
                </FormControl>
              </form>
            </div>
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  }

  isNextDisabled = ( stepIndex ) => {
    const { startDate, endDate } = this.props;

    switch (stepIndex) {
      case 0:
        //console.log("Check", startDate, endDate)
        if(startDate != null && endDate != null) {
          return false;
        } else {
          return true;
        }
      case 1:
        return this.state.reportType != null ? false : true;
      case 2:
        return true;
      default:
        return false;
    }
  }

  reportNameFromType = (reportId) => {
    switch(reportId) {
      case 'tech-bvb-report':
        return 'Booked vs Billed | Technician Jobs Report';
      case "agent-bvb-report-period":
        return 'Booked vs Billed | Sales Period Report';
      case "tech-bvb-report-period":
        return 'Booked vs Billed | Technician Period Report';
      case "scheduled-duration-bvb-report-period":
        return 'Booked vs Billed | Scheduled Period Report';
      default:
        return null;
    }
  }

  downloadPDFLink = () => {
    const REQUEST_URL_PDF = `https://reporting.brhapps.com/reports/${this.state.report_id}/download`;

    var axiosRequestObject = {
      method: 'get',
      url: REQUEST_URL_PDF,
      responseType: 'blob'
    }

    if(periodTime.includes(this.state.reportType)) {
      if(this.state.valueSlider || this.state.hideHours) {
        axiosRequestObject.params = {
          periodTime: parseInt( this.state.valueSlider , 10),
          hideHours: Boolean(JSON.parse(this.state.hideHours))
        }
      }
    }
    this.setState({ downloadPDFisPending: true })
    return axios( axiosRequestObject )
      .then(response => {
        //TODO: IE support with window.navigator.msSaveOrOpenBlob
        var a = document.createElement("a");
        var file = new Blob([response.data]); //, {type: "application/pdf"}
        a.href = URL.createObjectURL(file);
        a.download = this.state.report_id + '.pdf';
        this.setState({downloadPDFisPending: false})
        a.click();
      })
      .catch(err => {
        console.log("Critical error", err)
        this.setState({downloadPDFisPending: false})
      })
  }

  downloadCSVData = () => {
    var csvRaw = this.state.csvRaw;
    if(csvRaw === null) {
      window.alert("How did this happen? 😅... Download failed :(")
    } else {
      //TODO: IE support with window.navigator.msSaveOrOpenBlob
      var a = document.createElement("a");
      var file = new Blob([csvRaw], {type: "text/csv"});
      a.href = URL.createObjectURL(file);
      a.download = this.state.report_id + '.csv';
      a.click();
    }
  }

  render() {
    const { startDate, endDate, classes } = this.props;
    const activeStep = this.state.activeStep;
    const steps = this.state.steps;

    var isDatesSet = false;
    if(startDate != null && endDate != null) {
      isDatesSet = true;
    }

    return (
      <Fragment>
        <AppNavBar showBackBtn={true} onBackBtnClickAction={() => this.navigateToHome()} />
        <div className={classes.root}>
        {activeStep !== steps.length ? (
          <Stepper activeStep={activeStep}>
              <Step key={'step_1'}>
                <StepLabel>{ isDatesSet ? `Step 1 (${startDate.format('MM-DD-YYYY')} to ${endDate.format('MM-DD-YYYY')})` : 'Step 1' }</StepLabel>
              </Step>
              <Step key={'step_2'}>
                <StepLabel>{ this.state.reportType != null ? `Step 2 (${ this.reportNameFromType(this.state.reportType) })` : 'Step 2' }</StepLabel>
              </Step>
          </Stepper>
        ) : null }
          {activeStep === steps.length ? (
            <div style={{margin: "1rem"}}>
              <Button onClick={() => this.handleReset()}>Reset</Button>
            </div>
          ) : null }
          <div style={ activeStep === steps.length ? centerDivStyle : null }>
            {activeStep === steps.length ? (
              <div style={{ display: 'inline-flex', flexDirection: 'column', padding: '1rem', width: '80%' }}>
                {this.state.readyToDownload ? (
                  <div style={{display: 'flex', flexDirection: 'column', margin: "auto", textAlign: 'center'}}>
                    <div style={{ margin: 'auto auto 2rem auto' }}>
                      <Typography variant="h2">{`Your report is ready! 🎉`}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.65rem', alignItems: 'center' }}>
                      <Typography variant="h6" style={{marginBottom: '0.35rem'}}>{this.reportNameFromType(this.state.reportType)}</Typography>
                      <Typography variant="h5">{`${startDate.format('MM-DD-YYYY')} to ${endDate.format('MM-DD-YYYY')}`}</Typography>
                    </div>
                    <div style={{ display: 'inline-flex', flexGrow: '1', flexDirection: 'column', margin: '1rem 3rem 1rem'}}>
                      {
                        periodTime.includes(this.state.reportType) ? (
                          <div style={{ paddingBottom: '2rem' }}>
                            <InputLabel htmlFor="outlined-slider-simple">Period ({valuetext(this.state.valueSlider)})</InputLabel>
                            <Slider
                              value={this.state.valueSlider}
                              onChange={(event, newValue) => this.setState({ valueSlider: parseInt(newValue, 10) })}
                              defaultValue={1}
                              getAriaValueText={valuetext}
                              aria-labelledby="outlined-slider-simple"
                              step={1}
                              marks={marks}
                              min={1}
                              max={31}
                              valueLabelDisplay="auto"
                              ValueLabelComponent={ValueLabelComponent}
                            />
                          </div>
                        ) : null
                      }
                      {
                        periodTime.includes(this.state.reportType) ? (
                          <div style={{ display: 'inline-flex', flexDirection: 'row', width: '100%' }}>
                            <InputLabel style={{margin: 'auto 0.25rem auto auto'}} htmlFor="outlined-checkbox-simple">Hide Hours</InputLabel>
                            <Checkbox
                              disabled={this.state.downloadPDFisPending}
                              checked={Boolean(JSON.parse(this.state.hideHours))}
                              onChange={(event) => this.setState({ hideHours: Boolean(JSON.parse(event.target.checked)) })}
                              value="hideHours"
                              color="primary"
                              inputProps={{
                                'aria-label': 'hide hours checkbox',
                              }}
                            />
                          </div>
                        ) : null
                      }
                    </div>
                    <Button style={{margin: '1rem', padding: '1rem'}} disabled={this.state.downloadPDFisPending} variant="contained" color="primary" onClick={() => this.downloadPDFLink()}>Download PDF</Button>
                    <Button style={{margin: '1rem', padding: '1rem'}} disabled={this.state.downloadPDFisPending} variant="contained" color="secondary" onClick={() => this.downloadCSVData()}>Download CSV</Button>
                    <p style={{margin: '1rem', padding: '1rem'}}>Report ID: {this.state.report_id}</p>
                  </div>
                ) : (
                  <Fragment>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.65rem', justifyContent: 'space-between' }}>
                      <Typography variant="h6">{this.reportNameFromType(this.state.reportType)}</Typography>
                      <Typography variant="h5">{`${startDate.format('MM-DD-YYYY')} to ${endDate.format('MM-DD-YYYY')}`}</Typography>
                    </div>
                    <LinearProgress />
                    <Typography variant="subtitle2" style={{marginTop: '0.35rem'}}>Generating report, please wait...</Typography>
                    <Typography variant="subtitle1" style={{marginTop: '0.35rem'}}>Your report will be available below shortly.</Typography>
                    <Typography variant="subtitle1">This report will be available in PDF & CSV file formats once it has been processed by our rendering server.</Typography>
                  </Fragment>
                )}
              </div>
            ) : (
              <div>
                {this.getStepContent(activeStep)}
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={() => this.handleBack()}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button disabled={this.isNextDisabled( activeStep )} variant="contained" color="primary" onClick={() => this.handleNext()}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

var mapStateToProps = (state) => {
  const { startDate, endDate, isReady } = state.rootReducer;
  if(!isProduction) console.log("state", state)
  return { startDate, endDate, isReady };
}

export default connect(mapStateToProps, { updateDates })(withStyles(useStyles)(ReportGenScreen));
/*
<div className="main-chart-container">
  <div>{startDate !== null ? startDate.format() : null}</div>
  <div>{endDate !== null ? endDate.format() : null}</div>
</div>
*/
