import React, { Component, Fragment } from 'react';
import FancyBar from 'components/FancyBarChart';
import OptionsActionBar from 'components/OptionsActionBar';
import { chartData, isProduction } from 'datastore/constants';
import { connect } from 'react-redux'
import { getTotal } from 'actions';
import AppNavBar from 'components/AppNavBar';

class GraphOverviewScreen extends Component {
  constructor(props) {
    super(props);
    var chartTotals = {}
    chartData.map(x => chartTotals[x.name] = "N/A")
    this.state = {
      chartData: chartData,
      chartTotals: chartTotals
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { totalsMatrix } = this.props;
    if(!isProduction) console.log("[GraphOverviewScreen] totalsMatrix", totalsMatrix)
    if(totalsMatrix !== prevProps.totalsMatrix) {
      Object.keys(totalsMatrix).map( x => this.updateChartTotals( x, totalsMatrix[x] ) )
    }
  }

  updateChartTotals = ( chartName, total ) => {
    if(!isProduction) console.log("[GraphOverviewScreen] updateChartTotals - ", chartName, total)
    var totals = this.state.chartTotals;
    totals[chartName] = total;
    return this.setState({ chartTotals: totals })
  }

  generateCharts = () => {
    //const { startDate, endDate } = this.props;
    return this.state.chartData.map((chartObj, chartIndx) => {
      //Required props: chartName, API_URL, queryParams, appendQueryParams, apiDateVersion, barColor
      /*
      var dateRangeTitle =  (startDate != null && endDate != null) ? ` (${moment(startDate).startOf('day').format("MM-DD-YYYY")} - ${endDate.startOf('day').format("MM-DD-YYYY")})` : '';
      var chartTitle = chartObj.name != null ? ( chartObj.name + ` ${dateRangeTitle}`) : "Chart Title"
      */
      var chartTitle = chartObj.name != null ? chartObj.name : "Chart Title"
      return (
        <div key={encodeURIComponent(chartObj.name) + '_' + chartIndx} style={{ margin: '1rem', display: 'inline-flex', flex: '1 0 25%', flexDirection: 'column', border: '1px solid black'}}>
          <div style={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'nowrap', whiteSpace: 'nowrap', margin: '1rem auto' }}>
            <h2 style={{ margin: 'auto', textAlign: 'center', color: chartObj.barColor}}>{ chartTitle }</h2>
            <h2 style={{ margin: 'auto auto auto 0.5rem' }}>(Total: { this.state.chartTotals[ chartObj.name ] })</h2>
          </div>
          <FancyBar
            chartName={chartObj.name}
            API_URL={chartObj.api_url}
            queryParams={chartObj.queryParams}
            appendQueryParams={chartObj.appendQueryParams}
            apiDateVersion={chartObj.apiDateVersion}
            barColor={chartObj.barColor}
          />
        </div>
      );
    })
  }

  navigateToHome = () => {
    return this.props.history.push( '/home' )
  }

  render() {
    return (
      <Fragment>
        <AppNavBar showBackBtn={true} onBackBtnClickAction={() => this.navigateToHome()} />
        <OptionsActionBar />
        <div className="main-chart-container">
          {this.generateCharts()}
        </div>
      </Fragment>
    );
  }
}

var mapStateToProps = (state) => {
  const { startDate, endDate, isReady, totalsMatrix } = state.rootReducer;
  if(!isProduction) console.log("state", state)
  return { startDate, endDate, isReady, totalsMatrix };
}

export default connect(mapStateToProps, { getTotal })( GraphOverviewScreen )
