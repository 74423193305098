import React, { Component, Fragment } from 'react';
import DashboardComponent from 'components/Dashboard';
import AppNavBar from 'components/AppNavBar';
import withAuthorization from 'components/auth/withAuthorization';

class DashboardScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardId: null
    }
  }

  navigateToDashboardHome = () => {
    return this.props.history.push('/dashboards')
  }

  navigateToDashboardId = ( dashboardId ) => {
    return this.props.history.push(`/dashboard/edit/${dashboardId}`, { prevPath: this.props.location.pathname })
  }

  componentDidMount() {
    console.log("[Component][DashboardScreen] Dashboard ID", this.props.match.params.id)
    this.setState({dashboardId: this.props.match.params.id})
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.match.params.id !== prevProps.match.params.id) {
      console.log("[Component][DashboardScreen] Changed ID!", this.props.match.params.id)
      this.setState({dashboardId: this.props.match.params.id})
    }
  }

  render() {
    return (
      <Fragment>
        <AppNavBar
          showBackBtn={true}
          onBackBtnClickAction={() => this.navigateToDashboardHome()}
        />
        <DashboardComponent navigateToDashboardHome={this.navigateToDashboardHome} navigateToDashboardId={this.navigateToDashboardId} id={this.state.dashboardId} />
      </Fragment>
    )
  }
}

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(DashboardScreen);
