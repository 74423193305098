//import { combineReducers } from 'redux';
import BRH_API_Reducer from './BRHReducer';

export default BRH_API_Reducer;
//TODO: make consitent with store.js
/*
export default combineReducers({
    BRH_API_Reducer: BRH_API_Reducer
});
*/
