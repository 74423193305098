export const isProduction = true;
export const chartData = [
  {
    name: "Unique Callers",
    api_url: "https://api-v2.brhapps.com/v1/metrics/unique_calls_leads/",
    //api_url: "https://api.brhapps.com/v1/Count/UniqueCalls",
    queryParams: {
      startDate: null,
      endDate: null
    },
    appendQueryParams: true,
    apiDateVersion: 'v2',
    barColor: 'rgb(231, 76, 60)'
  },
  {
    name: "Voicemails",
    api_url: "https://api-v2.brhapps.com/v1/metrics/voicemails/",
    //api_url: "https://api.brhapps.com/v1/Count/Voicemail",
    queryParams: {
      startDate: null,
      endDate: null
    },
    appendQueryParams: true,
    apiDateVersion: 'v2',
    barColor: 'rgb(52, 152, 219)'
  },
  {
    name: "Inbound calls",
    api_url: "https://api-v2.brhapps.com/v1/metrics/inbound_calls/",
    //api_url: "https://api.brhapps.com/v1/Count/InboundCalls",
    queryParams: {
      startDate: null,
      endDate: null
    },
    appendQueryParams: true,
    apiDateVersion: 'v2',
    barColor: 'rgb(26, 188, 156)'
  },
  {
    name: "Outbound calls",
    api_url: "https://api-v2.brhapps.com/v1/metrics/outbound_calls/",
    //api_url: "https://api.brhapps.com/v1/Count/OutboundCalls",
    queryParams: {
      startDate: null,
      endDate: null
    },
    appendQueryParams: true,
    apiDateVersion: 'v2',
    barColor: 'rgb(241, 196, 15)'
  },
  {
    name: "Hours scheduled",
    api_url: "https://api.brhapps.com/v2/Count/MonthlyHours",
    queryParams: {
      from: null,
      to: null,
      booked: 0
    },
    appendQueryParams: false,
    apiDateVersion: 'v1',
    barColor: 'rgb(230, 126, 34)'
  },
  {
    name: "Hours booked",
    api_url: "https://api.brhapps.com/v2/Count/MonthlyHours",
    queryParams: {
      from: null,
      to: null,
      booked: 1
    },
    appendQueryParams: false,
    apiDateVersion: 'v1',
    barColor: 'rgb(155, 89, 182)'
  },
  {
    name: "Hours worked",
    api_url: "https://api-v2.brhapps.com/v1/metrics/worked_hours/",// 2019-05-01/2019-05-31
    queryParams: {
      startDate: null,
      endDate: null
    },
    appendQueryParams: true,
    apiDateVersion: 'v2',
    barColor: 'rgb(192, 57, 43)'
  },
  {
    name: "Unique Inbound",
    api_url: "https://api-v2.brhapps.com/v1/metrics/unique_inbound_calls/",// 2019-05-01/2019-05-31
    queryParams: {
      startDate: null,
      endDate: null
    },
    appendQueryParams: true,
    apiDateVersion: 'v2',
    barColor: 'rgb(88, 177, 159)'
  },
  {
    name: "Unique Outbound",
    api_url: "https://api-v2.brhapps.com/v1/metrics/unique_outbound_calls/",// 2019-05-01/2019-05-31
    queryParams: {
      startDate: null,
      endDate: null
    },
    appendQueryParams: true,
    apiDateVersion: 'v2',
    barColor: 'rgb(234, 181, 67)'
  },
  {
    name: "Unique In+Out",
    api_url: "https://api-v2.brhapps.com/v1/metrics/unique_both_calls/",// 2019-05-01/2019-05-31
    queryParams: {
      startDate: null,
      endDate: null
    },
    appendQueryParams: true,
    apiDateVersion: 'v2',
    barColor: 'rgb(125, 95, 255)'
  },
];

export const chartClassStyles = {
  [chartData[0].name]: "chartOne",
  [chartData[1].name]: "chartTwo",
  [chartData[2].name]: "chartThree",
  [chartData[3].name]: "chartFour",
  [chartData[4].name]: "chartFive",
  [chartData[5].name]: "chartSix",
  [chartData[6].name]: "chartSeven",
  [chartData[7].name]: "chartEight",
  [chartData[8].name]: "chartNine",
  [chartData[9].name]: "chartTen",
};

export const dashboardDataRef = "/metrics_app/dashboards";

export const periodTimes = [{
    value: "Today",
    startDate: { number: 0, unit: 'days' },
    endDate: { number: 0, unit: 'days' }
},
{
    value: "Today & Yesterday",
    startDate: { number: 1, unit: 'days' },
    endDate: { number: 0, unit: 'days' }
},{
    value: "Last 7 days (Including today)",
    startDate: { number: 6, unit: 'days' },
    endDate: { number: 0, unit: 'days' }
},{
    value: "Last 14 days (Including today)",
    startDate: { number: 13, unit: 'days' },
    endDate: { number: 0, unit: 'days' }
},{
    value: "Last 30 days (Including today)",
    startDate: { number: 29, unit: 'days' },
    endDate: { number: 0, unit: 'days' }
},{
    value: "Yesterday only",
    startDate: { number: 1, unit: 'days' },
    endDate: { number: 1, unit: 'days' }
},{
    value: "Last 7 days (Excluding today)",
    startDate: { number: 7, unit: 'days' },
    endDate: { number: 1, unit: 'days' }
},{
    value: "Last 14 days (Excluding today)",
    startDate: { number: 14, unit: 'days' },
    endDate: { number: 1, unit: 'days' }
},{
    value: "Last 30 days (Excluding today)",
    startDate: { number: 30, unit: 'days' },
    endDate: { number: 1, unit: 'days' }
}];

export const refreshTimes = [
  {
    value: "1 minute",
    time: 60
  },{
    value: "5 minutes",
    time: 300
  },{
    value: "15 minutes",
    time: 900
  },{
    value: "30 minutes",
    time: 1800
  },{
    value: "1 hour",
    time: 3600
  },{
    value: "4 hours",
    time: 14400
  },{
    value: "8 hours",
    time: 28800
  },{
    value: "12 hours",
    time: 43200
  },{
    value: "24 hours",
    time: 86400
  }
]

export const dataSources = [{name: "Close Opportunities", url: "https://api-v2.brhapps.com/v1/metrics/opportunities/startDate/endDate", version: 'v2'}]

export const API_DATE_FORMAT = {
  'v1': "MM-DD-YYYY",
  'v2': "YYYY-MM-DD"
}
