import React, { Component } from 'react';
import './index.css';
import { auth } from 'datastore/firebase';
import {
  withRouter
} from 'react-router-dom'

class LogOutScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    return auth.signOut()
      .then(() => {
        var pathName = "/home";
        if(this.props.location.state) {
          pathName = this.props.location.state.prevPath;
        }
        console.log("[LogOutScreen] Signed out!", pathName)
        return this.props.history.push(pathName, { prevPath: pathName })
      })
  }


  render() {
    return (
      <p className="logout-text">Logging off...</p>
    );
  }
}

export default withRouter(LogOutScreen)
