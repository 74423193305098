//import React, { Component, Fragment } from 'react';
import React, { Component } from 'react';
import Odometer from 'react-odometerjs';
import Typography from '@material-ui/core/Typography';
import 'odometer/themes/odometer-theme-default.css';
import './index.css';
import SettingsIcon from '@material-ui/icons/Settings';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import { dashboardDataRef, dataSources, refreshTimes, periodTimes, API_DATE_FORMAT } from 'datastore/constants';
import { database } from 'datastore/firebase';
import moment from 'moment';
import axios from 'axios';
import Promise from 'bluebird';

var filterArrayByArrayOfFilters = (array, filters) => {
  console.log("filterArrayByArrayOfFilters", array, filters)
  const filterKeys = Object.keys(filters);
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') return true;

      return filters[key](item[key]);
    });
  });
}

export default class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    var dataList = {}
    dataSources.map(x => {
      if(!dataList[x.name]) {
        dataList[x.name] = []
      }
      return dataList[x.name];
    })
    console.log("apiData", dataList)
    this.state = {
      dashboardList: [],
      apiData: dataList,
      refreshIntervalInSeconds: 300,
    }
    this.intervalId = null;
  }

  updateRefreshTime = ( dashboardItem ) => {
    var refreshIndx = refreshTimes.map(x => x.value).indexOf(dashboardItem.refreshInterval);
    if(refreshIndx === -1) {
      refreshIndx = 1;
    }
    var refreshTime = refreshTimes[refreshIndx].time;
    return new Promise(resolve => this.setState({ refreshIntervalInSeconds: refreshTime }, () => resolve()))
  }

  updatePeriod = ( dashboardItem ) => {
    var selectedIndx = periodTimes.map(x => x.value).indexOf(dashboardItem.period);
    console.log("selectedIndx", selectedIndx)
    var selectedPeriod = periodTimes[selectedIndx];
    var startNum = selectedPeriod.startDate.number;
    var startUnit = selectedPeriod.startDate.unit;
    var endNum = selectedPeriod.endDate.number;
    var endUnit = selectedPeriod.endDate.unit;
    return new Promise(resolve => this.setState({ period: { startDate: moment().subtract(startNum, startUnit).startOf('day'), endDate: moment().subtract(endNum, endUnit).endOf('day') } }, () => resolve()))
  }

  componentDidMount() {
    console.log("[Component][Dashboard] Dashboard ID", this.props.id)
    if(this.props.id !== null)
    {
      return this.getDashboardDataFromFirebase(this.props.id)
        .then( snap => {
          var dashboardData = snap.val()
          if(!dashboardData) {
            throw new Error("JigError/Invalid_Dashboard_ID")
          } else {
            var dashboardItem = null;
            try {
              dashboardItem = JSON.parse(dashboardData)
            } catch (e) {
              console.log("Failed to parse JSON data!")
            }

            if(dashboardItem === null) {
              throw new Error("JigError/Invalid_Dashboard_ID")
            }

            console.log("dashboardItem", dashboardItem)
            return this.updatePeriod( dashboardItem )
              .then(() => {
                return this.updateRefreshTime( dashboardItem )
              })
              .then(() => {
                return new Promise(resolve => this.setState({ dashboardData: dashboardItem}, () => resolve()))
              })
          }
        })
        .catch(err => {
          console.error(err)
          return this.props.navigateToDashboardHome()
        })
    }
  }

  componentWillUnmount() {
    if(this.intervalId) {
      clearInterval( this.intervalId )
    }

    this.intervalId = null;
    this.setState({ dashboardData: undefined, tileData: undefined })
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.id !== prevProps.id) {
      console.log("[Component][Dashboard] Changed ID!", this.props.id)
      if(this.intervalId) {
        clearInterval( this.intervalId )
      }
      this.intervalId = null;

      if(this.props.id !== null)
      {
        return this.getDashboardDataFromFirebase(this.props.id)
          .then( snap => {
            var dashboardData = snap.val()
            if(!dashboardData) {
              throw new Error("JigError/Invalid_Dashboard_ID")
            } else {
              var dashboardItem = null;
              try {
                dashboardItem = JSON.parse(dashboardData)
              } catch (e) {
                console.log("Failed to parse JSON data!")
              }

              if(dashboardItem === null) {
                throw new Error("JigError/Invalid_Dashboard_ID")
              }

              console.log("dashboardItem", dashboardItem)
              return this.updatePeriod( dashboardItem )
                .then(() => {
                    return this.updateRefreshTime( dashboardItem )
                })
                .then(() => {
                  return new Promise(resolve => this.setState({ dashboardData: dashboardItem}, () => resolve()))
                })
            }
          })
          .then(() => {
            console.log("Dashboard ready!", this.state.refreshIntervalInSeconds)
            if(this.intervalId) {
              clearInterval( this.intervalId )
            }
            return this.refreshData()
              .then( () => {
                this.intervalId = setInterval( this.refreshData.bind(this), ( this.state.refreshIntervalInSeconds * 1000 ))
              })
          })
          .catch(err => {
            console.error(err)
            return this.props.navigateToDashboardHome()
          })
      }
    }

    if(this.state.dashboardData !== undefined ) {
      if(prevState.dashboardData === undefined) {
        this.setState({ tileData: this.state.dashboardData.data })
      } else if(this.state.dashboardData.id !== prevState.dashboardData.id) {
        this.setState({ tileData: this.state.dashboardData.data })
      } else {
        console.log("EDGE CASE FOUND!")
        console.log("this.state.dashboardData", this.state.dashboardData)
        console.log("prevState.dashboardData", prevState.dashboardData)
      }
    }
  }

  getData(datasourceName) {
    var apiData = this.state.apiData;
    var datasourceIndx = dataSources.map(obj => obj.name).indexOf(datasourceName)
    var API_DETAILS = dataSources[datasourceIndx]
    if(API_DETAILS === undefined) {
      throw new Error("JigError/API_NOT_FOUND")
    }
    var API_DATE_FMT = API_DATE_FORMAT[API_DETAILS.version];
    var startDate = moment(this.state.period.startDate).startOf('day').format(API_DATE_FMT) || moment().startOf('week').format(API_DATE_FMT)
    var endDate = moment(this.state.period.endDate).endOf('day').format(API_DATE_FMT) || moment().endOf('week').format(API_DATE_FMT)
    var axiosRequestObject = {
      method: 'get',
      url: API_DETAILS.url,
      responseType: 'json'
    }

    axiosRequestObject.url = axiosRequestObject.url.replace("startDate", startDate);
    axiosRequestObject.url = axiosRequestObject.url.replace("endDate", endDate);

    return axios(axiosRequestObject)
      .then(function(response) {
        console.log("getData", response.data)
        if(response.status === 200)
          return response.data;
        else
          throw response.data.error;
      })
      .then(newData => {
        console.log("newData", newData)

        apiData[datasourceName].data = newData.data;
        return new Promise((resolve) => this.setState({"apiData": apiData}, () => resolve()));
      })
      .catch(function(error){
        //TODO: Send back to motherbase!
        console.error(error);
        throw error;
      })
  }

  getDashboardDataFromFirebase = ( dashboardId ) => {
    return database.ref(`${dashboardDataRef}/${dashboardId}`).once("value")
  }

  /*
  getDashboardDataFromFirebase = ( dashboardId ) => {
    console.log("test", `${dashboardDataRef}/${dashboardId}`)
    return database.ref(`${dashboardDataRef}/${dashboardId}`).once("value")
      .then(snap => {
        var dashboardData = snap.val()
        var dataToParse = null;
        try {
          dataToParse = JSON.parse(dashboardData)
        } catch (e) {
          console.log("Failed to parse JSON data!")
        }

        if(dataToParse === null) {
          throw new Error("JigError/Invalid_Dashboard_ID")
        } else {
          return dataToParse;
        }
      })
      .then(dashboardItem => {
        console.log("dashboardItem", dashboardItem)
        var modalValue = this.state.modalValue;
        modalValue.datasources = dashboardItem.datasources;
        this.editPeriod( dashboardItem.period )
          .then(() => {
            return new Promise(resolve => this.setState({ id: dashboardId, modalValue: modalValue, tileData: dashboardItem.data, name: dashboardItem.name, refreshInterval: dashboardItem.refreshInterval }, () => resolve()))
          })
      })
  }
  */

  openSettingsModal = () => {
    //window.alert("TODO: Open Settings Dialog")
    //Add refresh interval, edit dashboard, delete dashboard, rename dashboard
  }

  refreshData = () => {
    console.log("RefreshData called", this.state.dashboardData)
    var datasources = this.state.dashboardData.datasources;
    return Promise.all(datasources.map(dataSource => {
      return this.getData(dataSource)
    }))
    .then(() => {

      return Promise.all(this.state.dashboardData.data.map((dashboardItem, rowIndx) => {
        return Promise.all(dashboardItem.data.map((tileItem, tileIndx) => {
          var localFilters = {};
          if(!localFilters[rowIndx]) {
            localFilters[rowIndx] = {}
          }

          if(!localFilters[rowIndx][tileIndx]) {
            localFilters[rowIndx][tileIndx] = {}
          }
          console.log(`${JSON.stringify(dashboardItem)} - ${JSON.stringify(tileItem)}`)
          //TODO: Add refreshing ability to DashboardComponent
          var apiName = tileItem.api_name;
          var apiData = this.state.apiData[apiName].data;
          var filters = tileItem.filters;
          var valueName = tileItem.value_name;
          var valueType = tileItem.value_type;
          //tileValue = reduceByItem( valueName, valueType )
          //Get data from apiData
          console.log("apiName", apiName)
          console.log("apiData", apiData)
          console.log("filters", filters)

          //Run filters

          return Promise.all(filters.map(x => {
            console.log("filters", x, rowIndx, tileIndx)
            if(x.key === 'date') {
              var dates = []
              //TODO: Date format parsing v1/v2 switching based on API
              for(let i = moment(this.state.period.startDate).startOf('day'); moment(i).isBefore(this.state.period.endDate); i.add(1, 'days')) {
                dates.push( moment(i).startOf('day').format("YYYY-MM-DD") )
              }

              dates = dates.sort().reverse()
              //console.log("x.value", x.value)
              var dateChecks = []
              x.value.map(dateIndx => {
                console.log("dates[dateIndx]", dates[dateIndx], dateIndx)
                return dateChecks.push({ startDate: moment(dates[dateIndx]).startOf('day'), endDate: moment(dates[dateIndx]).endOf('day') })
              })
              var newChk = (valueToCheck) => {
                var val = moment(valueToCheck, "YYYY-MM-DD").startOf('day');
                var dateCheck = Object.assign([], dateChecks)
                var truthy = false;
                dateCheck.map(dateCh => {
                  if(moment(val).isBetween(dateCh.startDate, dateCh.endDate, null, "[]")) {
                    truthy = true;
                  }
                  return truthy;
                })
                return truthy;
              }

              localFilters[rowIndx][tileIndx][ x.key ] = newChk;
              return localFilters[rowIndx][tileIndx][ x.key ];
            } else {
              var chkVal = (valueToCheck) => { return valueToCheck === x.value }
              localFilters[rowIndx][tileIndx][ x.key ] = chkVal;
              return localFilters[rowIndx][tileIndx][ x.key ];
            }
          }))
          .then((allFilters) => {
            //console.log("localFilters", Object.keys(localFilters[rowIndx][tileIndx]), rowIndx, tileIndx, allFilters)
            var finalValue = [];
            finalValue = filterArrayByArrayOfFilters( apiData, localFilters[rowIndx][tileIndx] )

            var reduceByItem = (key, type) => {
              //console.log(`reduceByItem(${key}, ${type})`)
              return finalValue.reduce((total, obj) => {
                switch(type) {
                  case 'currency':
                    return parseFloat(total + parseFloat( parseFloat(obj[key]/100).toFixed(2) ));
                  default:
                    return total + obj[key];
                }
              }, 0)
            }

            //Reduce by valueName
            //Format as valueType
            //Set tileValue
            console.log("zoom zoom", finalValue)
            var newVal = reduceByItem( valueName, valueType )
            console.log(`reduceByItem( ${valueName}, ${valueType} ) = `, newVal, rowIndx, tileIndx)
            return { value: newVal, tileNum: tileIndx, rowNum: rowIndx }
          })
        }))
      }))
      .then((newTileData) => {
        var currentTileData = this.state.tileData;
        console.log("update the state", newTileData, currentTileData)
        return Promise.all(newTileData.map(rowObj => {
          return Promise.all(rowObj.map( tileObj => {
            return currentTileData[ tileObj.rowNum ].data[ tileObj.tileNum ].value = tileObj.value;
          }))
        }))
        .then(() => {
          return new Promise( resolve => {
            return this.setState({ tileData: currentTileData }, resolve())
          });
        })
      })
    })
  }

  editDashboard = () => {
    if(this.props.navigateToDashboardId) {
      return this.props.navigateToDashboardId(this.state.dashboardData.id)
    }
  }

  formatOdometer = ( valueType ) => {
    console.log("valueType", valueType)
    switch(valueType) {
      case 'currency':
        return 'odometer-currency'
      default:
        return '';
    }
  }

  render() {
    return this.state.dashboardData !== undefined ? (
      <div style={{ backgroundColor: '#202124', padding: '1rem 0rem 1rem 0rem'}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <EditIcon onClick={() => this.editDashboard()} style={{ cursor: 'pointer', color: 'grey', height: 'auto', width: 'calc(2vw + 2rem)', margin: 'auto 1rem auto auto'}}  />
          <Typography variant="h2" component="h1" style={{ margin: 'auto 0px auto 1rem', textAlign: 'center', padding: '1rem 0rem', color: 'white', fontWeight: 'bold', fontSize: 'calc(2vw + 2rem)' }}>
            {typeof(this.state.dashboardData.name) === 'string' ? this.state.dashboardData.name : "Dashboard"}
          </Typography>
          <SettingsIcon onClick={() => this.openSettingsModal()} style={{ cursor: 'pointer', color: 'grey', height: 'auto', width: 'calc(2vw + 2rem)', margin: 'auto 0px auto 1rem'}} />
          <RefreshIcon onClick={() => this.refreshData()} style={{ cursor: 'pointer', color: 'grey', height: 'auto', width: 'calc(2vw + 2rem)', margin: 'auto auto auto 1rem'}}  />
        </div>
        <div className="dashboard-app" style={{paddingTop: '1rem'}}>
          <div className="dashboard-container">
            <div className="metrics-container">
              <div className="metrics-scroll-container">
              {
                this.state.tileData !== undefined ?
                  this.state.tileData.map((tileRow, rowIndx) => {
                    return (
                      <div className={`metric-row ${tileRow.type}`} key={'metric_row_' + rowIndx}>
                        {
                            tileRow.data.map((tileObj, tileIndx) => {
                              return (
                                <div className='metric-tile' key={'metric_row_' + rowIndx + '_tile_' + tileIndx}>
                                  <div className={'metric-tile-value ' + this.formatOdometer(tileObj.value_type)}>
                                    <Odometer value={tileObj.value}/>
                                  </div>
                                  <b className={'metric-tile-title'}>{tileObj.name}</b>
                                </div>
                              );
                            })
                        }
                        </div>
                    )
                  }) : null
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div key={`dashboard_loading_state`}>
        <p>Loading...</p>
      </div>
    )
  }
}
