import React, { Component, Fragment } from 'react';
import TileButton from 'components/TileButtons';
//import Grid from '@material-ui/core/Grid';
import AppNavBar from 'components/AppNavBar';
import Typography from '@material-ui/core/Typography';

export default class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screensAvailable: [
        {
          id: "graphs",
          name: "Graphs",
          type: "graph",
          subtitle: "(Job + Call data)",
          description: ["Aggregate 7 bar graphs related to job & call metrics by any date range.", "Bar graphs are vertically listed for ease of browsing."]
        },
        {
          id: "pivot-table",
          name: "Pivot Table",
          type: "table",
          subtitle: "(Job data only)",
          description: ["Get access to all available job data served via Pivot Table.", "Job data is available to be aggregated by booked or scheduled.", "CSV export available!"]
        },
        {
          id: "reports",
          name: "Reports Generator",
          type: "aggregator",
          subtitle: "(Job data only)",
          description: ["Export corporate data quickly & reliably", "Necessary for business intelligence and key performance indicators", "Data available in PDF, CSV, and printer-friendly formats"]
        },
        {
          id: "dashboards",
          name: "Dashboard Generator",
          type: "dashboard",
          subtitle: "(BETA)",
          description: ["Create dynamic dashboards with data you actually want to see.", "Dashboards are local to your device while in beta", "Cloud sync dashboards require firebase integration"]
        }
      ]
    }
  }

  navigateToWebsite = ( tileId ) => {
    return this.props.history.push( tileId, { prevPath: this.props.location.pathname })
  }

  navigateToHome = () => {
    return this.props.history.push( '/home' )
  }

  generateNavigationTiles = () => {
    return this.state.screensAvailable.map((tileObj, tileIndx) => {
      return (
        <div key={'tile_btn_' + tileIndx} className={'tile-button-container'}>
          <TileButton
            title={ tileObj.name }
            subtitle={ tileObj.subtitle }
            description={ tileObj.description }
            clickAction={() => this.navigateToWebsite(tileObj.id)}
            iconType={ tileObj.type }
          />
        </div>
      );
    })
  }

  render() {
    return (
      <Fragment>
        <AppNavBar
          showBackBtn={false}
          onBackBtnClickAction={() => this.navigateToHome()}
        />
        <Typography variant="h2" component="h1" style={{ textAlign: 'center', padding: '2rem 1rem 1rem 1rem', color: '#2c3e50', fontWeight: 'bold', fontSize: 'calc(2vw + 2rem)' }}>
          Metrics Selection
        </Typography>
        <div className="main-chart-container">
          {this.generateNavigationTiles()}
        </div>
      </Fragment>
    );
  }
}
