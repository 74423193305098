import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthUserContext from 'components/auth/AuthUserContext';
import { auth } from 'datastore/firebase';

const withAuthorization = (authCondition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = auth.onAuthStateChanged(authUser => {
        if (!authCondition(authUser)) {
          console.log("DETECTED SIGNOUT!", this.props.location)
          this.props.history.push("/login", {
            prevPath: this.props.location.pathname
          });
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser => authUser ? <Component {...this.props} /> : null}
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorization);
}

export default withAuthorization;
