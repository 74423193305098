import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';

export default class ModalHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    console.log("this.props.handleModalChange", this.props.handleModalChange)
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={() => this.props.handleClose(this.props.testVal)}
      >
        {this.props.modalContent !== undefined ? this.props.modalContent(this.props.type, this.props.testVal, this.props.handleModalChange) : (<div></div>)}
      </Modal>
    )
  }
}
