import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SignoutButton from 'components/SignOffButton';

const styles = {
  root: {
    flexGrow: 1,
  },
  backButton: {
    marginRight: 16,
  },
  title: {
    flexGrow: 1,
  },
}

class AppNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null
    }
  }

  componentDidMount() {
    console.log("[Component][AppNavBar] mounted")
  }


  updateAuthUser = ( authUser ) => {
    return new Promise((resolve) => this.setState({ authUser: authUser }, () => resolve(authUser)))
  }

  render() {
    const { classes, showBackBtn, onBackBtnClickAction } = this.props;

    var backBtnAction = onBackBtnClickAction;

    if(!backBtnAction) {
      backBtnAction = () => {};
    }

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            {
              showBackBtn === true ? (
                <IconButton edge="start" className={classes.backButton} color="inherit" aria-label="Back" onClick={() =>  backBtnAction()}>
                  <ArrowBack />
                </IconButton>
              ) : null
            }
            <Typography variant="h6" className={classes.title}>
              BRH Metrics
            </Typography>
            <SignoutButton updateAuthUser={this.updateAuthUser} />
            <Button onClick={() => window.location.reload(true)} color="inherit">Reset</Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(AppNavBar);
