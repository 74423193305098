import React from 'react';
import Button from '@material-ui/core/Button';
import { auth } from 'datastore/firebase';
import { withRouter } from "react-router-dom";

class SignoutButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null
    }
    this.listener = null;
  }

  componentDidMount() {
    this.listener = auth.onAuthStateChanged(
      authUser => {
        authUser
          ? this.updateAuthUser( authUser )
          : this.updateAuthUser( null );
      },
    );
  }

  updateAuthUser = ( authUser ) => {
    return new Promise((resolve) => this.setState({ authUser: authUser }, () => resolve( this.props.updateAuthUser( authUser ) )))
  }

  componentWillUnmount() {
    this.listener();
  }

  redirectToLogoutScreen = () => {
    console.log("redirectToLogoutScreen", this.props.location.pathname)
    return this.props.history.push("/logout", { prevPath: this.props.location.pathname })
  }

  render() {
    var { authUser } = this.state;
    return authUser ? (
      <Button onClick={() => this.redirectToLogoutScreen()} color="secondary">Logout</Button>
    ) : null
  }
}

export default withRouter(SignoutButton)
