import React, { Component, Fragment } from 'react';
import AppNavBar from 'components/AppNavBar';
import Typography from '@material-ui/core/Typography';
import TileButton from 'components/TileButtons';
import CreateIcon from '@material-ui/icons/Create';
import withAuthorization from 'components/auth/withAuthorization';
import { database } from 'datastore/firebase';
import { dashboardDataRef } from 'datastore/constants';

class DashboardHomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardList: []
    }
    this.dashboardListSubscription = null;
  }

  redirectToDashboardId = (dashboardId) => {
    return this.props.history.push(`/dashboard/view/${dashboardId}`, { prevPath: this.props.location.pathname })
  }

  navigateToHome = () => {
    return this.props.history.push( '/home' )
  }

  updateDashboardList = ( data ) => {
    let dashboardList = [];
    data.forEach(childSnapshot => {
      console.log("updateDashboardList()", childSnapshot.key, childSnapshot.val())
      let snapValParsed = JSON.parse(childSnapshot.val())
      let dashboardData = Object.assign({}, snapValParsed, { id: childSnapshot.key });
      dashboardList.push(dashboardData)
    })

    this.setState({ dashboardList: dashboardList });
  }

  subscribeToDashboardList = () => {
    this.dashboardListSubscription = database.ref(dashboardDataRef).on("value", this.updateDashboardList)
  }

  unsubscribeToDashboardList = () => {
    this.dashboardListSubscription.off();
    this.dashboardListSubscription = null;
  }

  componentDidMount() {
    this.subscribeToDashboardList();
  }

  generateNavigationTiles = () => {
    return this.state.dashboardList.map((tileObj, tileIndx) => {
      console.log("tileObj", tileObj)
      return (
        <div key={'tile_btn_' + tileIndx} className={'tile-button-container'}>
          <TileButton
            title={ tileObj.name }
            subtitle={ null }
            description={ null }
            clickAction={() => this.redirectToDashboardId(tileObj.id)}
            iconType={ 'dashboard' }
          />
        </div>
      );
    })
  }

  openDashboardGenerator = () => {
    var dashboardId = database.ref(dashboardDataRef).push();
    return dashboardId.then((snap) => {
      const key = snap.key;
      return this.props.history.push(`/dashboard/edit/${key}`, { prevPath: this.props.location.pathname })
    })
  }

  render() {
    return (
      <Fragment>
        <AppNavBar
          showBackBtn={true}
          onBackBtnClickAction={() => this.navigateToHome()}
        />
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Typography variant="h2" component="h1" style={{ margin: 'auto 0px auto auto', textAlign: 'center', padding: '1rem 0rem', color: '#2c3e50', fontWeight: 'bold', fontSize: 'calc(2vw + 2rem)' }}>
            Dashboard Selection
          </Typography>
          <CreateIcon onClick={() => this.openDashboardGenerator()} style={{ cursor: 'pointer', color: 'grey', height: 'auto', width: 'calc(2vw + 2rem)', margin: 'auto auto auto 1rem'}} />
        </div>
        <div className="main-chart-container">
          {this.generateNavigationTiles()}
        </div>
      </Fragment>
    )
  }
}

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(DashboardHomeScreen);

/*
<div style={{backgroundColor: '#212121'}}>
  <ul>
    {this.state.dashboardList.map((dashboardObj, dashboardIndx) => {
      return (
        <li key={'dashboard_item_' + dashboardObj.id} onClick={() => this.redirectToDashboardId(dashboardObj.id)}>{dashboardObj.name}</li>
      );
    })}
  </ul>
</div>

*/
