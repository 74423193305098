import React, { Component, Fragment } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyComponent from 'react-plotly.js/factory';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import OptionsActionBar from 'components/OptionsActionBar';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import axios from 'axios';
import Papa from 'papaparse';
import AppNavBar from 'components/AppNavBar';
import { connect } from 'react-redux'
import { isProduction } from 'datastore/constants';

// create Plotly React component via dependency injection
const Plot = createPlotlyComponent(window.Plotly);

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const API_URL = "https://api-v2.brhapps.com/v1/metrics/customer_types/booked/"

const defaultColumns = ["job_id", "worker_id", "customer_id", "Customer Type", "B2B Type", "planned_duration", "actual_duration", "created_by_name"]

class PivotTableScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvRaw: null,
      csvData: [],
      pageLoadedTS: moment().unix(),
      isReady: false
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    //this.setState({ hasError: true });
    console.log(error, info)
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.props;
    if(startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
        return this.fetchData()
    }
  }


  fetchData = () => {
    var startDt = this.props.startDate;
    var endDt = this.props.endDate;

    if(startDt != null && endDt != null) {
    this.setState({ csvData: [], csvRaw: null })
    var startDate = moment(startDt).startOf('day').format("YYYY-MM-DD");
    var endDate = moment(endDt).endOf('day').format("YYYY-MM-DD");
    console.log("Test", startDt, endDt)

    var axiosRequestObject = {
      method: 'get',
      url: API_URL + startDate + '/' + endDate,
      responseType: 'json'
    }

    return axios( axiosRequestObject )
      .then(response => {
        console.log("test", response)
        var requestData = response.data;
        if(requestData.success) {
          return requestData.data;
        } else {
          return false;
        }
      })
      .then( data => {
        if(data) {
          var csv = Papa.unparse(data, { columns: defaultColumns });
          var ohnana = Papa.parse(csv);
          console.log("ohnana", ohnana.data, data, csv)
          this.setState({ csvRaw: csv, csvData: ohnana.data })
        } else {
          console.log("Show an error?")
        }
      })
      .catch(err => {
        console.log("[PivotTableScreen]", err)
        return true;
      })
    } else {
      console.log("what the hell")
      return new Promise(() => { return false })
    }
  }

  downloadCSVData = () => {
    var csvRaw = this.state.csvRaw;
    if(csvRaw === null) {
      window.alert("How did this happen? 😅... Download failed :(")
    } else {
      //TODO: IE support with window.navigator.msSaveOrOpenBlob
      var a = document.createElement("a");
      var file = new Blob([csvRaw], {type: "text/csv"});
      a.href = URL.createObjectURL(file);
      a.download = this.state.pageLoadedTS + '.csv';
      a.click();
    }
  }

  navigateToHome = () => {
    return this.props.history.push( '/home' )
  }

  render() {
    //const { isReady } = this.state;
    return (
      <Fragment>
        <AppNavBar showBackBtn={true} onBackBtnClickAction={() => this.navigateToHome()} />
        <div className="pivot-tbl-container">
          <OptionsActionBar>
            <Button variant="contained" color="primary" onClick={() => this.downloadCSVData()} disabled={ this.state.csvRaw === null }>Download data (CSV)</Button>
          </OptionsActionBar>
          <div className="pivot-tbl-container">
            <h2>Booked hours</h2>
            <div className="pivot-tbl">
              <PivotTableUI
                  data={this.state.csvData}
                  onChange={s => this.setState(s)}
                  renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                  {...this.state}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}


var mapStateToProps = (state) => {
  const { startDate, endDate, isReady } = state.rootReducer;
  if(!isProduction) console.log("state", state)
  return { startDate, endDate, isReady };
}

export default connect(mapStateToProps, {})( PivotTableScreen )
