import { BRH_API } from 'actions/types';
import { chartData } from 'datastore/constants';
//import moment from 'moment';
const FETCH_TEST_DATA = BRH_API.FETCH_TEST_DATA;
const FETCH_TEST_DATA_SUCCESS = BRH_API.FETCH_TEST_DATA_SUCCESS;
const FETCH_TEST_DATA_FAILURE = BRH_API.FETCH_TEST_DATA_FAILURE;

const defaultBRHAPIState = {
  isReady: false,
  startDate: null,
  endDate: null,
  keysInMatrix: chartData.map(x => x.name),
  totalsMatrix: {}
}

export default function BRH_API_Reducer(state=defaultBRHAPIState, action) {
  switch (action.type) {
    case FETCH_TEST_DATA:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        isReady: action.payload.isReady
      }
    case FETCH_TEST_DATA_SUCCESS:
      var newTotalsMatrix = Object.assign({}, state.totalsMatrix);
      var keyName = action.payload.keyName;
      newTotalsMatrix[ keyName ] = action.payload.keyData;
      return {
        ...state,
        totalsMatrix: newTotalsMatrix
      }
    case FETCH_TEST_DATA_FAILURE:
    default:
      return state;
  }
}
