import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateDates } from 'actions';
import { isProduction } from 'datastore/constants';
import { DateRangePicker } from 'react-dates';

const selectStates = {
  startDate: 'Start Date',
  endDate: 'End Date'
}

class OptionsActionBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
    }
  }

  render() {
    const { startDate, endDate } = this.props;
    
    return (
      <div className="options-container">
        <div className="dateRangePicker">
          <DateRangePicker
            enableOutsideDays={true}
            startDateId="startDate"
            endDateId="endDate"
            numberOfMonths={1}
            minimumNights={0}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={({ startDate, endDate }) => {
              return this.props.updateDates( startDate, endDate )
            }}
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) => { this.setState({ focusedInput })}}
            isOutsideRange={() => false}
          />
          <p style={{ margin: 'auto 2rem', fontWeight: 'bold' }}>{this.state.focusedInput != null ? ("Selecting " + selectStates[this.state.focusedInput]) : "Idle"}</p>
          {this.props.children}
        </div>
      </div>
    );
  }
}

var mapStateToProps = (state) => {
  const { startDate, endDate, isReady } = state.rootReducer;
  if(!isProduction) console.log("state", state)
  return { startDate, endDate, isReady };
}

export default connect(mapStateToProps, { updateDates })( OptionsActionBar )
